import moment from 'moment';
import { keys } from '../../utilities/translator/translation_keys';

export const expenseTable = ({
  id,
  user,
  date,
  total,
  type,
  description,
  status,
}) => [
  { title: id, query: 'id', className: 'xsmall' },
  { title: user, query: 'creator', type: 'user', className: 'medium' },
  { title: date, query: 'dateOfExpense', className: 'small' },
  {
    title: total,
    query: 'total',
    subquery: 'currency',
    type: 'number',
    className: 'small',
    hide: 'hide',
  },
  { title: type, query: 'type', className: 'large', type: 'type' },

  {
    title: description,
    query: 'description',
    hide: 'hide',
  },

  { title: status, query: 'status', type: 'label', className: 'small' },
];

export const reverseStringLookupType = {
  '5783 - Billed Travel': keys.expenses.TYPE_BILLED_TRAVEL,
  '5730 - Motor Vehicle Expense': keys.expenses.TYPE_MOTOR_VEHICLE,
  '5731 - Fuel': keys.expenses.TYPE_FUEL,
  '5700 - Office Supplies': keys.expenses.TYPE_OFFICE,
  '5775 - PPE': keys.expenses.TYPE_PPE,
  '5786 - Entertainment': keys.expenses.TYPE_ENTERTAINMENT,
  Mileage: keys.expenses.TYPE_MILEAGE,
  Advertising: keys.expenses.TYPE_ADVERTISING,
  Cleaning: keys.expenses.TYPE_CLEANING,
  'Consulting/Accounting': keys.expenses.TYPE_CONSULTING_ACCOUNTING,
  'Professional Fees': keys.expenses.TYPE_PROFESSIONAL_FEES,
  'Meals/Entertainment': keys.expenses.TYPE_MEALS_ENTERTAINMENT,
  'Freight/Courier': keys.expenses.TYPE_FREIGHT,
  General: keys.expenses.TYPE_GENERAL,
  Insurance: keys.expenses.TYPE_INSURANCE,
  Legal: keys.expenses.TYPE_LEGAL,
  'Education/Training': keys.expenses.TYPE_TRAINING,
  'Per Diem': keys.expenses.TYPE_PER_DIEM,
  'Printing/Stationary': keys.expenses.TYPE_PRINTING,
  'Rent/Lease': keys.expenses.TYPE_RENT,
  'Repairs/Maintenance': keys.expenses.TYPE_REPAIR,
  'Employee Benefits': keys.expenses.TYPE_BENEFITS,
  Subscriptions: keys.expenses.TYPE_SUBSCRIPTION,
  'Telephone/Internet': keys.expenses.TYPE_INTERNET,
  'Travel (National)': keys.expenses.TYPE_TRAVEL_NATIONAL,
  'Travel (International)': keys.expenses.TYPE_TRAVEL_INTERNATIONAL,
};

export const expenseTopInputs = [
  {
    title: keys.common.DATE,
    query: 'dateOfExpense',
    type: 'Date',
    required: true,
  },
  {
    title: keys.common.TYPE,
    query: 'type',
    type: 'dropdown',
    options: [
      {
        text: keys.expenses.TYPE_BILLED_TRAVEL,
        key: '5783 - Billed Travel',
      },
      { text: keys.expenses.TYPE_FUEL, key: '5731 - Fuel' },
      {
        text: keys.expenses.TYPE_MOTOR_VEHICLE,
        key: '5730 - Motor Vehicle Expense',
      },
      {
        text: keys.expenses.TYPE_OFFICE,
        key: '5700 - Office Supplies',
      },
      {
        text: keys.expenses.TYPE_PPE,
        key: '5775 - PPE',
      },
      {
        text: keys.expenses.TYPE_ENTERTAINMENT,
        key: '5786 - Entertainment',
      },
      {
        text: keys.expenses.TYPE_MILEAGE,
        key: 'Mileage',
      },
      {
        text: keys.expenses.TYPE_ADVERTISING,
        key: 'Advertising',
      },
      {
        text: keys.expenses.TYPE_CLEANING,
        key: 'Cleaning',
      },
      {
        text: keys.expenses.TYPE_CONSULTING_ACCOUNTING,
        key: 'Consulting/Accounting',
      },
      {
        text: keys.expenses.TYPE_PROFESSIONAL_FEES,
        key: 'Professional Fees',
      },
      {
        text: keys.expenses.TYPE_MEALS_ENTERTAINMENT,
        key: 'Meals/Entertainment',
      },
      {
        text: keys.expenses.TYPE_FREIGHT,
        key: 'Freight/Courier',
      },
      {
        text: keys.expenses.TYPE_GENERAL,
        key: 'General',
      },
      {
        text: keys.expenses.TYPE_INSURANCE,
        key: 'Insurance',
      },
      {
        text: keys.expenses.TYPE_LEGAL,
        key: 'Legal',
      },
      {
        text: keys.expenses.TYPE_TRAINING,
        key: 'Education/Training',
      },
      {
        text: keys.expenses.TYPE_PER_DIEM,
        key: 'Per Diem',
      },
      {
        text: keys.expenses.TYPE_PRINTING,
        key: 'Printing/Stationary',
      },
      {
        text: keys.expenses.TYPE_RENT,
        key: 'Rent/Lease',
      },
      {
        text: keys.expenses.TYPE_REPAIR,
        key: 'Repairs/Maintenance',
      },
      {
        text: keys.expenses.TYPE_BENEFITS,
        key: 'Employee Benefits',
      },
      {
        text: keys.expenses.TYPE_SUBSCRIPTION,
        key: 'Subscriptions',
      },
      {
        text: keys.expenses.TYPE_INTERNET,
        key: 'Telephone/Internet',
      },
      {
        text: keys.expenses.TYPE_TRAVEL_NATIONAL,
        key: 'Travel (National)',
      },
      {
        text: keys.expenses.TYPE_TRAVEL_INTERNATIONAL,
        key: 'Travel (International)',
      },
    ],
    required: true,
  },
  {
    title: keys.expenses.CURRENCY,
    query: 'currency',
    type: 'dropdown',
    options: [
      {
        text: keys.expenses.CURRENCY_CAD,
        key: 'CAD - Canadian Dollar',
      },
      {
        text: keys.expenses.CURRENCY_USD,
        key: 'USD - United States Dollar',
      },
      {
        text: keys.expenses.CURRENCY_GBP,
        key: 'GBP - British Pound',
      },
      {
        text: keys.expenses.CURRENCY_EUR,
        key: 'EUR - Euro',
      },
    ],
    required: true,
  },
  {
    title: keys.common.DESCRIPTION,
    query: 'description',
    type: 'textarea',
    required: true,
  },
];
export const expenseBottomInputs = [
  { title: keys.expenses.SUBTOTAL, query: 'subtotal', type: 'number' },
  { title: keys.expenses.TAX, query: 'tax', type: 'number' },
  { title: keys.expenses.TIP, query: 'tip', type: 'number' },
  { title: keys.expenses.OTHER_FEE, query: 'otherFee', type: 'number' },
  {
    title: keys.expenses.TOTAL,
    query: 'total',
    type: 'number',
    disabled: true,
  },
];

export const strings = {
  Draft: 'DRAFT',
  Pending: 'SUBMITTED',
  Approved: 'APPROVED',
  Denied: 'DENIED',
  Complete: 'COMPLETE',
};

export const expenseStatusTypes = {
  DRAFT: { title: keys.expenses.DRAFT, variant: 'blue' },
  SUBMITTED: { title: keys.expenses.PENDING, variant: 'yellow' },
  APPROVED: { title: keys.expenses.APPROVED, variant: 'green' },
  DENIED: { title: keys.expenses.DENIED, variant: 'red' },
  COMPLETE: { title: keys.expenses.COMPLETE, variant: 'purple' },
};

export const reviewerButtons = [
  {
    key: 'DENY',
    value: keys.expenses.REJECT_BUTTON,
    variant: 'danger',
    title: keys.expenses.REJECT_TITLE,
    description: keys.expenses.REJECT_DESCRIPTION,
    status: strings.Denied,
    textInput: true,
    navigate: 'team',
    textInputTitle: keys.expenses.REJECT_INPUT,
  },
  {
    key: 'APPROVE',
    value: keys.action.APPROVE,
    variant: 'success',
    title: keys.action.APPROVE_VARIABLE,
    description: keys.expenses.APPROVE_DESCRIPTION,
    status: strings.Approved,
    textInput: false,
    navigate: 'team',
  },
];
export const creatorButtons = [
  {
    key: 'SUBMIT',
    value: keys.action.SUBMIT,
    variant: 'primary',
    title: keys.action.SUBMIT_VARIABLE,
    description: keys.action.SUBMIT_CONFIRM_MESSAGE,
    status: strings.Pending,
    icon: 'check_circle',
    outlined: false,
    modalVariant: 'success',
  },
  {
    key: 'DELETE',
    value: keys.action.DELETE,
    variant: 'danger',
    title: keys.action.DELETE_VARIABLE,
    description: keys.action.DELETE_CONFIRMATION,
    icon: 'delete',
    outlined: true,
    modalVariant: 'danger',
  },
];
export const paidButton = {
  key: 'PAID',
  value: keys.common.COMPLETE,
  variant: 'purple',
  title: keys.expenses.COMPLETE_TITLE,
  description: keys.expenses.COMPLETE_MESSAGE,
  navigate: 'team',
  icon: 'paid',
};

export const requiredKeys = [
  'dateOfExpense',
  'type',
  'currency',
  'description',
];

export const keysToSum = ['subtotal', 'tip', 'tax', 'otherFee'];

export const CSVHeaders = {
  __typename: ' ',
  dateCreated: 'Date Created',
  dateOfExpense: 'Date of Expense',
  description: 'Description',
  workspace: 'Workspace',
  dateModified: 'Date Last Modified',
  reviewer: 'Reviewer',
  creator: 'Creator',
  currency: 'Currency',
  files: 'Files',
  id: 'ID',
  status: 'Status',
  dateSubmitted: 'Date Submitted',
  dateReviewed: 'Date Reviewed',
  datePaid: 'Date Completed',
  subtotal: 'Subtotal',
  otherFee: 'Other Fees',
  tax: 'Tax',
  tip: 'Tip',
  total: 'Total',
  type: 'Expense Code',
  distance: 'Distance Travelled',
  rate: 'Travel Rate',
  isMetric: 'Distance Units',
  comments: 'Comments',
  payrollId: 'Payroll ID',
  resourceId: 'Resource ID',
  activity: 'Activity',
  attachments: 'Attachment Links',
};

export const formatCSVData = (expense) => ({
  creator: `${expense?.creator?.firstName} ${expense?.creator?.lastName}`,
  dateOfExpense: expense.dateOfExpense,
  status: expense.status,
  description: expense.description,
  type: expense.type,
  subtotal: expense.subtotal?.toFixed(2) || '0.00',
  tax: expense.tax?.toFixed(2) || '0.00',
  tip: expense.tip?.toFixed(2) || '0.00',
  otherFee: expense.otherFee?.toFixed(2) || '0.00',
  total: expense.total?.toFixed(2) || '0.00',
  currency: expense.currency,
  comments: expense.comment,
  distance: expense.distance
    ? `${expense.distance} ${expense.isMetric ? 'km' : 'mi'}`
    : '',
  rate: expense?.distance
    ? `${expense.rate || 0}$/${expense.isMetric ? 'km' : 'mi'}`
    : '',
  reviewer:
    expense?.reviewer &&
    `${expense?.reviewer?.firstName} ${expense?.reviewer?.lastName}`,
  dateSubmitted:
    expense?.dateSubmitted &&
    moment(parseInt(expense.dateSubmitted)).format('YYYY-MM-DD HH:mm'),
  dateReviewed:
    expense?.dateReviewed &&
    moment(parseInt(expense.dateReviewed)).format('YYYY-MM-DD HH:mm'),
  datePaid:
    expense?.datePaid &&
    moment(parseInt(expense.datePaid)).format('YYYY-MM-DD HH:mm'),
  payrollId: `${expense.creator.payrollId}` || 'None',
  resourceId: expense.creator.externalActivityId || 'None',
  activity:
    expense.activity?.description ||
    expense.activity?.externalActivityId ||
    'N/A',
  workspace: expense.workspace.title,
  attachments: expense.files?.map((f) => f.url).join(', '),
});
