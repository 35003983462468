export const languages = {
  en: { nativeName: 'ENGLISH' },
  'fr-CA': { nativeName: 'FRENCH' },
};

export const keys = {
  settings: {
    OBSERVATIONNAVINFO: 'settings:companyNav.observationNavigation',
    INCIDENTNAVINFO: 'settings:companyNav.incidentNavigation',
    COMPANYNAVINFO: 'settings:companyNav.companyNavInfo',
    SETTINGS: 'settings:settings',
    PAYROLL_ID: 'settings:payrollID',
    CONTACT_EMAIL: 'settings:companyInfo.email',
    WEBSITE: 'settings:website',
    ADDRESS: 'settings:address',
    CITY: 'settings:city',
    POSTAL_CODE: 'settings:postalCode',
    PHONE: 'settings:phone',
    NOT_PROVIDED: 'settings:notProvided',
    INVITE: 'settings:invit.one',
    INVITES: 'settings:invite.few',
    USER_INVITES: 'settings:userInvites',
    COMPANY_LOGO: 'settings:companyInfo.logo',
    BASIC_INFO: 'settings:basicInformation',
    DATE_OF_BIRTH: 'settings:dateOfBirth',
    EMERGENCY_CONTACT: 'settings:emergencyContact',
    IMPORTANT_INFO: 'settings:importantInfo',
    COMPANY_NAME: 'settings:companyInfo.name',
    EMAIL: 'settings:email',
    INVITE_PLACEHOLDER: 'settings:invite.placeholder',
    PROFILE_PIC: 'settings:profilePicture',
    PROFILE: 'settings:profile',
    COMPANY: 'settings:companyInfo.company',
    REVOKE_INVITE: 'settings:invite.revokeInvite',
    REVOKE_INVITE_FOR: 'settings:invite.revokeInviteFor',
    INVALID_EMAIL: 'settings:invite.invalidEmail',
    CANNOT_ADD: 'settings:invite.cannotBeAdded',
    NO_INVITES: 'settings:invite.noInvites',
    CLEAR_CACHE_TITLE: 'settings:clearCache.title',
    CLEAR_CACHE_MESSAGE: 'settings:clearCache.message',
    CLEAR_CACHE: 'settings:clearCache.clear',
    CLEAR_CACHE_CONFIRMATION: 'settings:clearCache.clearConfirmation',
    REVOKE_INVITE_CONFIRMATION: 'settings:invite.revokeInviteConfirmation',
    BILLING: 'settings:billingInformation',
    BILLING_WARNING: 'settings:invite.billingWarning',
    SUPERVISOR: 'settings:supervisor',
    ONLINE_NOW: 'settings:userPage.onlineNow',
    NEVER_LOGGED_IN: 'settings:userPage.neverLoggedIn',
    ASSIGN_DIRECT_REPORTS: 'settings:userPage.assignDirectReports',
    DONE: 'settings:userPage.done',
    DIRECT_REPORTS: 'settings:userPage.directReports',
    VARIABLE_TEAM: 'settings:userPage.variableTeam',
    DIRECT_MANAGER_OVERSEEING_YOUR_WORK:
      'settings:userPage.directManagerOverseeingYourWork',
    TEAM_MEMBERS: 'settings:userPage.teamMembers',
    COLLEAGUES_WHO_REPORT_TO_SAME_SUPERVISOR:
      'settings:userPage.colleaguesWhoReportToSameSupervisor',
    TEAM_MEMBERS_YOU_MANAGE_DIRECTLY:
      'settings:userPage.teamMembersYouManageDirectly',
    COMPANY_ACCESS: 'settings:userPage.companyAccess',
    WORKSPACE_ACCESS: 'settings:userPage.workspaceAccess',
    NO_LINKED_FILES: 'settings:userPage.noLinkedFiles',
    NO_LINKED_FILES_MESSAGE: 'settings:userPage.noLinkedFilesMessage',
    NO_RECENT_HISTORY: 'settings:userPage.noRecentHistory',
    GO_MAKE_TODAY_PRODUCTIVE: 'settings:userPage.goMakeTodayProductive',
    PARTICIPATED_IN_ASSESSMENT: 'settings:userLog.participatedInAssessment',
    CLOSED_AN_ACTION_ITEM: 'settings:userLog.closedAnActionItem',
    REPORTED_AN_INCIDENT: 'settings:userLog.reportedAnIncident',
    COMPLETED_A: 'settings:userLog.completedA',
    SUPERVISOR_NOT_ASSIGNED: 'settings:userPage.supervisorNotAssigned',
    VARIABLE_ACTIVITY: 'settings:userPage.variableActivity',
    AUDIT_NAVIGATION: 'settings:companyNav.auditNavigation',
  },
  action: {
    ADD: 'action:add',
    UPLOAD_VARIABLE: 'action:upload.present',
    UPLOADED: 'action:select.past',
    SELECT_VARIABLE: 'action:select.present',
    SELECTED: 'action:select.past',
    ENTER: 'action:enter',
    SAVE: 'action:save',
    UPDATE_SUCCESSFUL: 'action:update.success',
    UPDATE_SUCCESS_MESSAGE: 'action:update.successMessage',
    UPDATE_FAIL: 'action:update.fail',
    UPDATE_FAIL_MESSAGE: 'action:update.failMessage',
    SEARCH: 'action:search',
    COPY: 'action:copy.present',
    COPIED: 'action:copy.past',
    REVOKE: 'action:revoke',
    CONFIRMATION_MESSAGE: 'action:messages.confirmation',
    CANCEL: 'action:cancel',
    INVITE: 'action:invite',
    EXPIRES: 'action:expire.present',
    EXPIRED: 'action:expire.past',
    DELETE_VARIABLE: 'action:delete.present',
    DELETED: 'action:delete.past',
    NOT_FOUND: 'action:emptyState.notFound',
    CONGRATS: 'action:messages.wayToGo',
    COMPLETED: 'action:complete.past',
    TO_COMPLETE: 'action:complete.present',
    START: 'action:start.start',
    START_TYPE: 'action:start.startType',
    SUBMIT: 'action:submit.submit',
    UPDATE: 'action:update.updateButton',
    SUBMIT_DISABLED_MESSAGE: 'action:submit.disabledMessage',
    PUBLISH: 'action:publish.present',
    PUBLISHED: 'action.publish.past',
    EMPTY_STATE_MESSAGE: 'action:emptyState.message',
    LEAVE: 'action:leave',
    CONFIRM: 'action:confirmation.confirm',
    CONFIRM_CHANGES: 'action:confirmation.confirmChanges',
    CREATE_VARIABLE: 'action:create.present',
    CREATED: 'action:create.past',
    ALREADY_EXISTS: 'action:messages.alreadyExists',
    REQUIRED: 'action:required',
    PUBLISH_CONFIRMATION: 'action:messages.publishConfirmation',
    DOWNLOAD_VARIABLE: 'action:download.present',
    DOWNLOADED: 'action:download.past',
    IMPORT: 'action:import.present',
    CHOOSE_IMAGE: 'action:choose.image',
    NEXT: 'action:next',
    BACK: 'action:back',
    THIS: 'action:delete.this',
    DELETE_IMAGE: 'action:delete.image',
    EMPTY_STATE_CHECK_FILTERS: 'action:emptyState.checkFilters',
    DOWNLOAD_CONFIRMATION: 'action:download.confirmation',
    EDIT: 'action:edit.edit',
    EDIT_VARIABLE: 'action:edit.present',
    DELETE_CONFIRMATION: 'action:delete.deleteConfirmation',
    CLEAR: 'action:clear',
    DOUBLE_CONFIRMATION_TEXT: 'action:delete.doubleConfirmText',
    DELETE: 'action:delete.delete',
    NO_MATCHING_RESULTS: 'action:emptyState.noMatchingResults',
    DOWNLOAD: 'action:download.download',
    CREATE: 'action:create.create',
    SELECT: 'action:select.select',
    UPLOAD: 'action:upload.upload',
    RESET: 'action:reset',
    CONTINUE: 'action:continue',
    REMINDER: 'action:reminder',
    LOADING: 'action:loading',
    LOGIN: 'action:login',
    RETURN_TO_WEBSITE: 'action:returnToWebsite',
    EXPORT: 'action:export',
    EXPORTDYNAMIC: 'action:exportDynamic',
    OPEN_IN_NEW_WINDOW: 'action:openInNewWindow',
    NEW: 'action:new',
    NEW_VARIABLE: 'action:newVariable',
    ERROR_BATCH_UPDATE_TITLE: 'action:errors.batchUpdateTitle',
    ERROR_BATCH_UPDATE_MESSAGE: 'action:errors.batchUpdateMessage',
    SUBMIT_VARIABLE: 'action:submit.variable',
    SUBMIT_CONFIRM_MESSAGE: 'action:submit.confirmMessage',
    DENY: 'action:deny.raw',
    DENY_VARIABLE: 'action:deny.variable',
    APPROVE: 'action:approve.raw',
    APPROVE_VARIABLE: 'action:approve.variable',
    REVISE: 'action:revise.raw',
    REVISE_VARIABLE: 'action:revise.variable',
    HIDE: 'action:hide',
    HIDE_VARIABLE: 'action:hideVariable',
    SHOW_VARIABLE: 'action:showVariable',
    DELETE_TEMPLATE_CONFIRMATION: 'action:deleteTemplateConfirmation',
    DEESCALATE: 'action:deescalate.raw',
    DEESCALATE_VARIABLE: 'action:deescalate.variable',
    DEESCALATE_CONFIRMATION: 'action:deescalate.confirmation',
    DISMISS: 'action:dismiss.raw',
    DISMISS_VARIABLE: 'action:dismiss.variable',
    DISMISS_CONFIRMATION: 'action:dismiss.confirmation',
    DELETE_TEMPLATE_CONFIRMATION_DRAFT:
      'action:deleteTemplateConfirmationDraft',
  },
  common: {
    USERS: 'common:user.few',
    AUDIT: 'common:audit',
    ACCESS: 'common:access',
    ADMIN: 'common:roles.admin',
    ADMIN_CONSOLE: 'common:adminConsole',
    STANDARD: 'common:roles.standard',
    WORKSPACE: 'common:workspace.workspace',
    DELETE: 'common:delete',
    NONE: 'common:none',
    SUPERVISOR: 'common:supervisor',
    FILE: 'common:file.one',
    FILES: 'common:file.few',
    USER: 'common:user.one',
    IMAGE: 'common:image.one',
    IMAGES: 'common:image.few',
    ERROR: 'common:error',
    USER_HAS: 'common:user.has',
    LINK: 'common:link',
    ACTIVE: 'common:active',
    NA: 'common:notAvailable',
    FIRST_NAME: 'common:user.firstName',
    LAST_NAME: 'common:user.lastName',
    ACTION_ITEMS: 'common:actionItem.few',
    ACTION_ITEM: 'common:actionItem.one',
    STATUS: 'common:fields.status',
    IN_PROGRESS: 'common:status.inProgress',
    TODO: 'common:status.toDo',
    BACKLOG: 'common:status.backlog',
    COMPLETED: 'common:status.completed',
    COMPLETE: 'common:status.complete',
    ASSESSMENT: 'common:assessment.one',
    ASSESSMENTS: 'common:assessment.few',
    DATE: 'common:fields.dates.date',
    PASSED: 'common:status.passed',
    FAILED: 'common:status.failed',
    TOTAL: 'common:total.one',
    TOTALS: 'common:total.few',
    QUESTION: 'common:question.one',
    QUESTIONS: 'common:question:few',
    TEMPLATE: 'common:template.one',
    TEMPLATES: 'common:template.few',
    NEW: 'common:new',
    TYPE: 'common:fields.type',
    SUBTYPE: 'common:fields.subtype',
    TITLE: 'common:fields.title',
    DYNAMIC_TITLE: 'common:dynamicFields.dynamicTitle',
    DYNAMIC_DESCRIPTION: 'common:dynamicFields.dynamicDescription',
    CREATOR: 'common:fields.creator',
    DRAFT: 'common:status.draft',
    PUBLISHED: 'common:status.published',
    PARTICIPANTS: 'common:fields.participants',
    REGRETS: 'common:fields.regrets',
    REVIEWERS: 'common:fields.reviewers',
    INFORMATION: 'common:headers.information',
    EMPLOYEE: 'common:user.employee',
    ADMINISTRATIVE: 'common:roles.administrative',
    ADMINISTRATOR: 'common:roles.administrator',
    OPTIONAL: 'common:optional',
    NUMBER: 'common:dataTypes.number',
    PROBABILITY: 'common:fields.probability',
    IMPACT: 'common:fields.impact',
    ASSET: 'common:asset.one',
    ASSETS: 'common:asset.few',
    DESCRIPTION: 'common:fields.description',
    IMAGE_DESCRIPTION_PLACEHOLDER: 'common:placeholders.imageDescription',
    LABEL: 'common:fields.label.one',
    LABELS: 'common:fields.label.few',
    DATE_CREATED: 'common:fields.dates.dateCreated',
    ASSIGNEE: 'common:fields.assignee',
    ASSIGNEES: 'common:fields.assignees',
    NOTES: 'common:fields.notes',
    NAME: 'common:fields.name',
    ROLE: 'common:fields.role',
    ROLES: 'common:fields.roles',
    NAME_OF: 'common:placeholders.nameOf',
    COLOUR: 'common:fields.colour',
    LABEL_EXISTS_MESSAGE: 'common:fields.label.labelExistsMessage',
    SIGNATURE: 'common:questionTypes.signature',
    BY: 'common:by',
    REFERENCE_IMAGE: 'common:reference.image_one',
    REFERENCE_IMAGES: 'common:reference.image_few',
    REQUIRED: 'common:required',
    OPTIONS: 'common:options',
    HOME: 'common:home',
    DETAILS: 'common:headers.details',
    LOCATION: 'common:fields.location',
    DATE_MODIFIED: 'common:fields.dates.dateModified',
    YES: 'common:yes',
    NO: 'common:no',
    PROGRESS: 'common:progress',
    INSPECTION: 'common:inspection',
    REPORT: 'common:report',
    INCIDENT: 'common:templateTypes.incident',
    QUALITY: 'common:templateTypes.quality',
    HSE: 'common:templateTypes.hse',
    VEHICLE: 'common:templateTypes.fleet',
    COMMISSIONING: 'common:templateTypes.commissioning',
    MEETING: 'common:templateTypes.meeting',
    DAILY_REPORT: 'common:templateTypes.dailyReport',
    FLEET: 'common:templateTypes.fleet',
    NO_NOTES_RECORDED: 'common:noNotesRecorded',
    REPORT_DATE: 'common:reportDate',
    REPORT_TIME: 'common:reportTime',
    BACK: 'common:back',
    CLEAR_ALL_FILTERS: 'common:clearAllFilters',
    FINISH: 'common:finish',
    SHOW: 'common:show',
    DATE_ADDED: 'common:fields.dates.dateAdded',
    ACCOUNT_ALREADY: 'common:accountAlready',
    HOLD_ON: 'common:holdOn',
    WORKSPACES: 'common:workspace.workspaces',
    TASK: 'common:tasks.single',
    TASKS: 'common:tasks.plural',
    OBSERVATION: 'common:observations.single',
    OBSERVATIONS: 'common:observations.plural',
    EXPENSE: 'common:expenses.single',
    EXPENSES: 'common:expenses.plural',
    BATCH_UPDATE_TITLE: 'common:batchUpdate.title',
    BATCH_UPDATE_APPROVE_MESSAGE: 'common:batchUpdate.approveMessage',
    BATCH_UPDATE_PAY_MESSAGE: 'common:batchUpdate.payMessage',
    BATCH_UPDATE_APPROVAL: 'common:batchUpdate.approval',
    BATCH_UPDATE_PAYMENT: 'common:batchUpdate.payment',
    BATCH_UPDATE_PROCESSING: 'common:batchUpdate.processing',
    MILES: 'common:distance.miles',
    KILOMETERS: 'common:distance.kilometers',
    REVIEWER: 'common:reviewer',
    APPROVER: 'common:approver',
    COMMENTS: 'common:comments',
    DATE_SUBMITTED: 'common:fields.dates.dateSubmitted',
    DATE_REVIEWED: 'common:fields.dates.dateReviewed',
    DATE_PAID: 'common:fields.dates.datePaid',
    TIMECARD: 'common:timecards:single',
    TIMECARDS: 'common:timecards:plural',
    PERSONAL: 'common:personal',
    TEAM: 'common:team',
    MY_TEAM_ONLY: 'common:myTeamOnly',
    INSIGHT: 'common:insights.single',
    INSIGHTS: 'common:insights.plural',
    ANNOUNCEMENT: 'common:announcements.single',
    ANNOUNCEMENTS: 'common:announcements.plural',
    BOARD: 'common:boards.single',
    BOARDS: 'common:boards.plural',
    EDITED: 'common:edited',
    FLAG_DISABLED: 'common:flagDisabled',
    ORIENTATION: 'common:templateTypes.orientation',
    MAINTENANCE: 'common:templateTypes.maintenance',
    REVISIONS: 'common:revisions',
    REVISION: 'common:revision',
    PREVIOUS_REVISION: 'common:previousRevision',
    REV: 'common:rev',
    EMERGENCY: 'common:templateTypes.emergency',
    INVALID: 'common:invalid',
    ON: 'common:on',
    CREATED_BY: 'common:createdBy',
    ATTACHMENTS: 'common:fields.attachments',
    FOLLOW: 'common:follow',
    UNFOLLOW: 'common:unfollow',
    GETTING_REPORTS: 'common:gettingReports',
    PRIVATE: 'common:private',
    DISMISSED: 'common:dismissed',
    DATE_COMPLETED: 'common:fields.dates.dateCompleted',
    CLOSED_BY: 'common:closedBy',
    CLOSED: 'common:closed',
    DATE_CLOSED: 'common:fields.dates.dateClosed',
    COMPLETED_BY: 'common:completedBy',
    LAST_UPDATED: 'common:lastUpdated',
    NO_WORKSPACE: 'common:noWorkspace',
    EDITED_ON: 'common:editedOn',
    REFERENCE_FILES: 'common:reference.referenceFiles',
    REFERENCE_FILES_OFFLINE: 'common:reference.referenceFilesOffline',
    COMPLIANCE: 'common:compliance',
    RESOURCE_ID: 'common:resourceId',
    MESSAGE: 'common:message',
    DONE: 'common:status.complete',
    DUPLICATE: 'common:status.duplicate',
  },
  incidents: {
    INCIDENT: 'incidents:incident.one',
    INCIDENTS: 'incidents:incident.few',
    INITIATED: 'incidents:status.initiated',
    INVESTIGATION: 'incidents:status.investigation',
    PLACEHOLDER: 'incidents:placeholder',
    TYPE: 'incidents:types.incidentType',
    SUBTYPE: 'incidents:subtypes.incidentSubtype',
    HEALTH_AND_SAFETY: 'incidents:types.hse',
    ENVIRONMENT: 'incidents:types.environment',
    QUALITY: 'incidents:types.quality',
    COMMERCIAL: 'incidents:types.commercial',
    ASSET: 'incidents:types.asset',
    FATALITY: 'incidents:subtypes.fatality',
    LOST_TIME: 'incidents:subtypes.lostTime',
    MEDICAL_AID: 'incidents:subtypes.medicalAid',
    FIRST_AID: 'incidents:subtypes.firstAid',
    NEAR_MISS: 'incidents:subtypes.nearMiss',
    SPILL_TO_LAND: 'incidents:subtypes.spillToLand',
    SPILL_TO_WATER: 'incidents:subtypes.spillToWater',
    WASTE_SEGREGATION: 'incidents:subtypes.wasteSegregation',
    INTERNAL_NON_CONFORMANCE: 'incidents:subtypes.internalNonConformance',
    VENDOR_NON_CONFORMANCE: 'incidents:subtypes.vendorNonConformance',
    CUSTOMER_COMPLAINT: 'incidents:subtypes.customerComplaint',
    CHANGE: 'incidents:subtypes.change',
    MAINTENANCE: 'incidents:subtypes.maintenance',
    FAILURE: 'incidents:subtypes.failure',
    DATE_OF_INCIDENT: 'incidents:questions.dateOfIncident',
    TIME_OF_INCIDENT: 'incidents:questions.timeOfIncident',
    LOCATION: 'incidents:questions.location',
    INCIDENT_DESCRIPTION: 'incidents:questions.incidentDescription',
    WEATHER_CONDITIONS: 'incidents:questions.weatherConditions',
    INITIAL_ROOT_CAUSE: 'incidents:questions.initialRootCause',
    WEATHER_OPTIONS: 'incidents:options.weatherOptions',
    ROOT_CAUSE_OPTIONS: 'incidents:options.rootCauseOptions',
    INTERNAL_EMPLOYEE: 'incidents:questions.internalEmployee',
    EXTERNAL_EMPLOYEE: 'incidents:questions.externalEmployee',
    LOST_TIME_QUESTION: 'incidents:questions.lostTimeQuestion',
    LOST_TIME_HRS: 'incidents:questions.lostTimeHrs',
    LOST_TIME_COST: 'incidents:questions.lostTimeCost',
    POTENTIAL_FATALITY: 'incidents:questions.potentialFatality',
    IMPACT_PROBABILITY: 'incidents:questions.impactProbability',
    ASSET_SELECTION: 'incidents:questions.assetSelection',
    YES_NO_OPTIONS: 'incidents:options.yesNo',
    ASSET_INVOLVED: 'incidents:questions.assetInvolved',
    INCIDENT_DETAILS: 'incidents:headers.incidentDetails',
    EMPLOYEE_DETAILS: 'incidents:headers.employeeDetails',
    ADMINISTRATIVE_DETAILS: 'incidents:headers.administrativeDetails',
    INCIDENT_REPORT: 'incidents:headers.incidentReport',
    INCIDENT_CLOSED: 'incidents:messages.incidentClosed',
    LEAVE_INCIDENT_MESSAGE: 'incidents:messages.leaveIncident',
    CLOSE_INCIDENT: 'incidents:messages.closeIncident',
    CLOSE_INCIDENT_MESSAGE: 'incidents:messages.closeIncidentMessage',
    INCIDENT_CLOSED_ON: 'incidents:messages.incidentClosedOn',
    INCIDENT_CLOSURE_DETAILS: 'incidents:pdf.incidentClosureDetails',
    CLOSED_BY: 'incidents:pdf.closedBy',
    CLOSER_SIGNAURE: 'incidents:pdf.closerSignature',
    DATE_INCIDENT_CLOSED: 'incidents:pdf.dateIncidentClosed',
    INCIDENT_IMAGES: 'incidents:pdf.incidentImages',
    NO_INCIDENT_IMAGES: 'incidents:pdf.noIncidentImages',
    INCIDENT_ASSESSMENTS: 'incidents:pdf.incidentAssessments',
    NO_INCIDENT_ASSESSMENTS: 'incidents:pdf.noIncidentAssessments',
    NO_IMAGE_DESCRIPTION: 'incidents:pdf.noImageDescription',
    EMPTY_STATE_TITLE: 'incidents:emptyState.title',
    EMPTY_STATE_MESSAGE: 'incidents:emptyState.message',
    REPORT_INCIDENT: 'incidents:headers.reportIncident',
    START_REPORT: 'incidents:headers.startReport',
    START_INCIDENT_ASSESSMENT: 'incidents:headers.startIncidentAssessment',
    NOT_SPECIFIED: 'incidents:messages.notSpecified',
    CREATE_INCIDENT_TEMPLATE: 'incidents:headers.createIncidentTemplate',
    OPPORTUNITY_FOR_IMPROVEMENT: 'incidents:subtypes.opportunityForImprovement',
    PENDING: 'incidents:status.pending',
    CLOSED: 'incidents:status.closed',
    INVALID: 'incidents:status.invalid',
    REVIEWER_INFORMATION: 'incidents:pdf.reviewerInformation',
    REVIEWED_DATE: 'incidents:pdf.reviewedDate',
    REVIEWED_BY: 'incidents:pdf.reviewedBy',
    COMPLETE: 'incidents:status.complete',
    ADMINISTRATION_OR_SUPPORT: 'incidents:subtypes.admin',
    ENGINEERING: 'incidents:subtypes.engineering',
    FLIGHT_OPERATION: 'incidents:subtypes.flight',
    FACILITIES: 'incidents:subtypes.facilities',
    HAZARD_IDENTIFICATION_OR_EVENT: 'incidents:subtypes.hazard',
    PMC: 'incidents:subtypes.pmc',
    SECURITY_EVENT: 'incidents:subtypes.security',
    REGULATORY_COMPLIANCE: 'incidents:subtypes.regulatory',
    HUMAN_FACTORS: 'incidents:subtypes.human',
    PASSENGER_RELATED: 'incidents:subtypes.passenger',
    AVIATION: 'incidents:types.aviation',
    INVESTIGATION_FORMS: 'incidents:headers.investigationForms',
    OTHER: 'incidents:subtypes.other',
    SUBMITTED: 'incidents:submitted',
    SUBMITTED_MESSAGE: 'incidents:submittedMessage',
    MISC: 'incidents:subtypes.misc',
  },
  templates: {
    TEMPLATE: 'templates:template.one',
    TEMPLATES: 'templates:template.few',
    ALL_WORKSPACES: 'templates:options.allWorkspaces',
    SECOND_SIGHT: 'templates:options.secondSight',
    APPROVAL_REQUIRED: 'templates:options.approvalRequired',
    COPY_AND_EDIT: 'templates:options.copyAndEdit',
    CATEGORY_NAME: 'templates:options.categoryName',
    CSV_UPLOAD_FAIL_MESSAGE: 'templates:csv.csvFail',
    CSV_INSTRUCTIONS: 'templates:csv.csvInstructions',
    CSV_FILE: 'templates:csv.csvFile',
    CSV_MODAL_MESSAGE: 'templates:csv.modalMessage',
    DOWNLOAD_CSV_TEMPLATE: 'templates:csv.downloadCSVTemplate',
    REFERENCE_IMAGE: 'templates:options.referenceImage',
    INCIDENT: 'templates:types.incident',
    TITLE_PLACEHOLDER: 'templates:placeholders.title',
    DESCRIPTION_PLACEHOLDER: 'templates:placeholders.description',
    CREATE_COPY_MESSAGE: 'templates:actions.createCopyMessage',
    CREATE_REVISION_MESSAGE: 'templates:actions.createRevisionMessage',
    QUALITY: 'templates:types.quality',
    HSE: 'templates:types.hse',
    VEHICLE: 'templates:types.fleet',
    COMMISSIONING: 'templates:types.commissioning',
    MEETING: 'templates:types.meeting',
    DAILY_REPORT: 'templates:types.meeting',
    CSV_UPLOAD_ERROR: 'templates:csv.uploadError',
    CSV_UPLOAD_MODAL_TEXT: 'templates:csv.modalMessage',
    CSV_TEMPLATE: 'templates:types.csv',
    ADD_CATEGORIES_TITLE: 'templates:emptyState.categoriesTitle',
    ADD_CATEGORIES_SUBTEXT: 'templates:emptyState.categoriesSubtext',
    CATEGORY_PLACEHOLDER: 'templates:placeholders.category',
    QUESTIONS_TITLE: 'templates:emptyState.questionsTitle',
    QUESTIONS_SUBTEXT: 'templates:emptyState.questionsSubtext',
    QUESTION_TITLE_PLACEHOLDER: 'templates:placeholders.question',
    QUESTION_DESCRIPTION_PLACEHOLDER:
      'templates:placeholders.questionDescription',
    ADD_TO: 'templates:add.addTo',
    QUESTION: 'templates:add.question',
    TEST: 'templates:questionTypes.test',
    TEST_TYPE: 'templates:questionTypes.testType',
    PASS_FAIL_N_A: 'templates:testTypes.passFailNA',
    PASS_FAIL: 'templates:testTypes.passFail',
    YES_NO_N_A: 'templates:testTypes.yesNoNA',
    YES_NO: 'templates:testTypes.yesNo',
    OK_ISSUE: 'templates:testTypes.okIssue',
    OK_ISSUE_N_A: 'templates:testTypes.okIssueNA',
    TABLE_COLUMN_PLACEHOLDER: 'templates:placeholders.tableColumn',
    SINGLE_LINE: 'templates:questionOptions.singleLine',
    MULTI_LINE: 'templates:questionOptions.multiLine',
    SINGLE: 'templates:questionOptions.single',
    MULTI: 'templates:questionOptions.multi',
    SELECT_OPTIONS: 'templates:questionOptions.selectOptions',
    OPTION_SET_TITLE: 'templates:emptyState.optionSetTitle',
    OPTION_SET_SUBTEXT: 'templates:emptyState.optionSetSubtext',
    OPTIONS_TITLE_PLACEHOLDER: 'templates:placeholders.optionsTitle',
    OPTION_SET_PLACEHOLDER: 'templates:placeholders.optionSet',
    ADD_OPTIONS: 'templates:add.addOptions',
    TEXT: 'templates:questionTypes.text',
    NUMBER: 'templates:questionTypes.number',
    IMAGE: 'templates:questionTypes.image',
    USER: 'templates:questionTypes.user',
    SELECT: 'templates:questionTypes.select',
    TABLE: 'templates:questionTypes.table',
    SIGNATURE: 'templates:questionTypes.signature',
    DATE: 'templates:questionTypes.date',
    MATRIX: 'templates:questionTypes.matrix',
    FILE: 'templates:questionTypes.file',
    COLUMN: 'templates:questionOptions.column',
    CATEGORY: 'templates:options:category',
    DELETE_CATEGORY: 'templates:actions.deleteCategory',
    DELETE_QUESTION: 'templates:actions.deleteQuestion',
    DELETE_CATEGORY_WARNING: 'templates:actions.deleteCategoryWarning',
    EMPTY_STATE_TITLE: 'templates:emptyState.title',
    EMPTY_STATE_MESSAGE: 'templates:emptyState.message',
    EMPTY_STATE_ADD_TEMPLATE: 'templates:emptyState.addTemplates',
    CREATE_MODAL_TITLE_PLACEHOLDER: 'templates:placeholders.createTitle',
    VOICE: 'assessments:voiceQuestion.voice',
    TIME: 'templates:questionOptions.time',
    DATE_TIME: 'templates:questionOptions.dateTime',
    DOWNLOAD_EXCEL_TEMPLATE: 'templates:csv.downloadExcelTemplate',
    OBSERVATION: 'templates:types.observation',
    SELECT_MULTI: 'templates:questionTypes.selectMulti',
    SELECT_SINGLE: 'templates:questionTypes.selectSingle',
    TEXT_MULTI: 'templates:questionTypes.textMulti',
    MULTI_TEXT: 'templates:questionTypes.multiText',
    IMAGE_MULTI: 'templates:questionTypes.multiImage',
    USER_MULTI: 'templates:questionTypes.multiUser',
    SIGNATURE_SINGLE: 'templates:questionTypes.sigSingle',
    SIGNATURE_MULTI: 'templates:questionTypes.sigMulti',
    FILE_SINGLE: 'templates:questionTypes.fileSingle',
    FILE_MULTI: 'templates:questionTypes.fileMulti',
    AUDIO: 'templates:questionTypes.audio',
    ORIENTATION: 'templates:types.orientation',
    MAINTENANCE: 'templates:types.maintenance',
    REVISION_NUMBER: 'templates:revisionNumber',
    EMERGENCY: 'templates:types.emergency',
    ASSET: 'templates:questionTypes.asset',
    FORM_ID: 'templates:documentNumbering:formId',
    STARTING_AT: 'templates:documentNumbering:startingAt',
    WORKSPACE_LOCATIONS: 'templates:workspaceLocations',
    COMPANY_LOCATIONS: 'templates:companyLocations',
  },
  files: {
    NEW_FOLDER: 'files:actions.newFolder',
    UPLOAD_FILE: 'files:actions.uploadFile',
    ADD_DIRECTORY: 'files:actions.addDirectory',
    GLOBAL: 'files:tabs.global',
    WORKSPACE: 'files:tabs.workspace',
    ALLOW_DOWNLOAD: 'files:actions.allowDownload',
    FILE_SIZE_WARNING: 'files:warning.fileSize',
    FILE_COMPLIANCE_WARNING: 'files:warning.complianceDisclaimer',
    TITLE_PLACEHOLDER: 'files:placeholders.title',
    ADD_YOUR_FILES: 'files:emptyState.addYourFiles',
    RENAME: 'files:actions.rename',
    FILE_NAME: 'files:fields.fileName',
    UPLOAD_REVISION: 'files:actions.uploadRevision',
    PRINT: 'files:actions.print',
    DELETE_DIRECTORY_WARNING: 'files:warning.deleteDirectoryFiles',
    EXTENSION: 'files:fields.extension',
    FILE_DETAILS: 'files:fields.fileDetails',
    FILE_TYPE: 'files:fields.fileType',
    SIZE: 'files:fields.size',
    REVISION: 'files:fields.revision',
    DOWNLOAD_ENABLED: 'files:actions.downloadEnabled',
    EMPTY_VIEWER_TITLE: 'files:emptyState.emptyViewerTitle',
    FILE_TYPE_MESSAGE: 'files:emptyState.fileTypeMessage',
    DOWNLOAD_DISABLED_MESSAGE: 'files:emptyState.downloadDisabledMessage',
    DOWNLOAD_DISABLED_MESSAGE_SHORT:
      'files:emptyState.downloadDisabledMessageShort',
    DOWNLOAD_DISABLED: 'files:downloadDisabled',
    ISSUED_FOR_INFORMATION: 'files:status.issuedForInformation',
    ISSUED_FOR_CONSTRUCTION: 'files:status.issuedForConstruction',
    REVIEWED: 'files:status.reviewed',
    ISSUED_FOR_TENDER: 'files:status.issuedForTender',
    ISSUED_FOR_USE: 'files:status.issuedForUse',
    REJECTED: 'files:status.rejected',
    ISSUED_FOR_REVIEW: 'files:status.issuedForReview',
    ISSUED_FOR_DESIGN: 'files:status.issuedForDesign',
    REVISE_AND_RESUBMIT: 'files:status.reviseAndResubmit',
    ISSUED_FOR_APPROVAL: 'files:status.issuedForApproval',
    ISSUED_FOR_FABRICATION: 'files:status.issuedForFabrication',
    REVIEWED_WITH_COMMENTS: 'files:status.reviewedWithComments',
    AS_BUILT: 'files:status.asBuilt',
    VOID: 'files:status.void',
    ISSUED_FOR_DESIGN_REVIEW: 'files:status.issuedForDesignReview',
    CLOSED: 'files:status.closed',
    REDLINE: 'files:status.redline',
    INSPECTION: 'files:types.inspection',
    DRAWING_REVIEW: 'files:types.drawingReview',
    INSURANCE: 'files:types.insurance',
    MINUTES_OF_MEETING: 'files:types.minutesOfMeeting',
    MODEL: 'files:types.model',
    ORG_CHART: 'files:types.orgChart',
    POLICY: 'files:types.policy',
    PROCEDURE: 'files:types.procedure',
    REPORT: 'files:types.report',
    REQUEST_FOR_TENDER: 'files:types.requestForTender',
    RESUME_CV: 'files:types.resumeCV',
    SAFETY_DATA_SHEET: 'files:types.safetyDataSheet',
    SCHEDULE: 'files:types.schedule',
    STANDARD: 'files:types.standard',
    REQUEST_FOR_INFORMATION: 'files:types.requestForInformation',
    SITE_INSTRUCTION: 'files:types.siteInstruction',
    FIELD_INSTRUCTION: 'files:types.fieldInstruction',
    WORK_PACKAGE: 'files:types.workPackage',
    TECHNICAL_SKETCH: 'files:types.technicalSketch',
    TECHNICAL_CHANGE_REQUEST: 'files:types.technicalChangeRequest',
    TECHNICAL_DATA_SHEET: 'files:types.technicalDataSheet',
    TECHNICAL_DRAWING: 'files:types.technicalDrawing',
    TECHNICAL_SPECIFICATION: 'files:types.technicalSpecification',
    TENDER: 'files:types.tender',
    OTHER_TECHNICAL: 'files:types.otherTechnical',
    OTHER_PROJECT_CONTROLS: 'files:types.otherProjectControls',
    OTHER_HUMAN_RESOURCES: 'files:types.otherHumanResources',
    OTHER_MAINTENANCE: 'files:types.otherMaintenance',
    OTHER_QUALITY: 'files:types.otherQuality',
    OTHER: 'files:types.other',
    DRAWING: 'files:fileTypes.drawing',
    IMAGE: 'files:fileTypes.image',
    SPREADSHEET: 'files:fileTypes.spreadsheet',
    PDF: 'files:fileTypes.pdf',
    WORD: 'files:fileTypes.word',
    VIDEO: 'files:fileTypes.video',
    UPLOAD_ERROR_TITLE: 'files:actions.error.title',
    UPLOAD_ERROR_MSG: 'files:actions.error.message',
    HIDDEN: 'files:hidden',
  },
  assets: {
    UNITS: 'assets:units',
    LAST_INSPECTION: 'assets:fields:lastInspection',
    OPERATING_HOURS: 'assets:fields.operatingHours',
    END_OF_LIFE_DATE: 'assets:fields:endOfLife',
    END_OF_LIFE_HOURS: 'assets:fields:endOfLifeHours',
    LOCATION: 'assets:fields:location',
    P1_SIDEBAR_HEADER: 'assets:create.p1sidebarHeader',
    P1_SIDEBAR_SUBTEXT: 'assets:create.p1sidebarSubtext',
    P1_HEADER: 'assets:create.p1header',
    P1_SUBTEXT: 'assets:create.p1subtext',
    BRAND: 'assets:fields:brand',
    MODEL: 'assets:fields:model',
    YEAR: 'assets:fields:year',
    UNIT_NUMBER: 'assets:fields:unitNumber',
    SERIAL_NUMBER: 'assets:fields:serialNumber',
    MAKE: 'assets:fields:make',
    VIN: 'assets:fields:vin',
    LICENSE_PLATE: 'assets:fields:licensePlate',
    ODOMETER: 'assets:fields:odometer',
    MILES: 'assets:fields:miles',
    KILOMETERS: 'assets:fields:kilometers',
    P2_HEADER: 'assets:create.p2header',
    P2_SIDEBAR_SUBTEXT: 'assets:create.p2sidebarSubtext',
    P2_SUBTEXT: 'assets:create.p2subtext',
    P3_SIDEBAR_HEADER: 'assets:create.p3sidebarHeader',
    P3_SIDEBAR_SUBTEXT: 'assets:create.p3sidebarSubtext',
    P3_HEADER: 'assets:create.p3header',
    P3_SUBTEXT: 'assets:create.p3subtext',
    P4_SIDEBAR_HEADER: 'assets:create.p4sidebarHeader',
    P4_SIDEBAR_SUBTEXT: 'assets:create.p4sidebarSubtext',
    P4_HEADER: 'assets:create.p4header',
    P4_SUBTEXT: 'assets:create.p4subtext',
    FEATURE_NAME: 'assets:fields.featureName',
    LOCATION_PLACEHOLDER: 'assets:placeholders.location',
    FEATURE_PLACEHOLDER: 'assets:placeholders.feature',
    FEATURE_DESCRIPTION_PLACEHOLDER: 'assets:placeholders.featureDescription',
    FEATURES: 'assets:fields.features.few',
    FEATURE: 'assets:fields.features.one',
    LIGHT_VEHICLE: 'assets:types.lightVehicle',
    RECREATION_VEHICLE: 'assets:types.recreationVehicle',
    HEAVY_EQUIPMENT: 'assets:types.heavyEquipment',
    'IT_&_OFFICE': 'assets:types.ITandOffice',
    'TOOLS_&_EQUIPMENT': 'assets:types.toolsAndEquipment',
    PROPERTY: 'assets:types.property',
    INDUSTRIAL_EQUIPMENT: 'assets:types.industrial',
    EMERGENCY_EQUIPMENT: 'assets:types.emergencyEquipment',
    OTHER: 'assets:subtypes.other',
    CAR: 'assets:subtypes.lv.car',
    SUV: 'assets:subtypes.lv.suv',
    VAN: 'assets:subtypes.lv.van',
    TRAILER: 'assets:subtypes.lv.trailer',
    MOTORCYCLE: 'assets:subtypes.lv.motorcycle',
    TRUCK: 'assets:subtypes.lv.truck',
    ATV: 'assets:subtypes.rv.atv',
    BOAT: 'assets:subtypes.rv.boat',
    SNOWMOBILE: 'assets:subtypes.rv.snowmobile',
    CRANE: 'assets:subtypes.heavy.crane',
    BULLDOZER: 'assets:subtypes.heavy.bulldozer',
    GENERATOR_TOWABLE_: 'assets:subtypes.heavy.generator',
    COMPRESSOR_TOWABLE_: 'assets:subtypes.heavy.compressor',
    FORKLIFT: 'assets:subtypes.heavy.forklift',
    FRONT_END_LOADER: 'assets:subtypes.heavy.fel',
    'SKID-STEER': 'assets:subtypes.heavy.ss',
    EXCAVATOR: 'assets:subtypes.heavy.exc',
    SEMI_LORRY: 'assets:subtypes.heavy.semi',
    TELEHANDLER: 'assets:subtypes.heavy.tel',
    DUMP_TRUCK: 'assets:subtypes.heavy.dump',
    VAC_TRUCK: 'assets:subtypes.heavy.vac',
    HEAVY_TRUCK: 'assets:subtypes.heavy.ht',
    BARGE: 'assets:subtypes.heavy.bar',
    IT_AND_OFFICE: 'assets:subtypes.it.itAndOffice',
    COMPUTER: 'assets:subtypes.it.comp',
    PHONE: 'assets:subtypes.it.phone',
    TABLET: 'assets:subtypes.it.tablet',
    CAMERA: 'assets:subtypes.it.cam',
    FURNITURE: 'assets:subtypes.it.furn',
    TEST_BENCH: 'assets:subtypes.tools.tb',
    HAND_TOOLS: 'assets:subtypes.tools.handtool',
    POWER_TOOLS: 'assets:subtypes.tools.pwrtool',
    WELDER: 'assets:subtypes.tools.weld',
    COMPRESSOR: 'assets:subtypes.tools.comp',
    LIFTING_EQUIPMENT: 'assets:subtypes.tools.lift',
    INSPECTION_EQUIPMENT: 'assets:subtypes.tools.insp',
    HYDRAULIC_EQUIPMENT: 'assets:subtypes.tools.hydraulic',
    PERSONAL_PROTECTIVE_EQUIPMENT: 'assets:subtypes.tools.ppe',
    FLIGHT_SUIT: 'assets:subtypes.tools.fsuit',
    FLIGHT_TOOLS: 'assets:subtypes.tools.ftools',
    HARNESS: 'assets:subtypes.tools.harness',
    HELMET: 'assets:subtypes.tools.helmet',
    IMMERSION_SUIT: 'assets:subtypes.tools.imsuit',
    LANYARD: 'assets:subtypes.tools.lanyard',
    LIGHT: 'assets:subtypes.tools.light',
    LAND: 'assets:subtypes.property.land',
    APPLIANCES: 'assets:subtypes.property.appliances',
    BUILDING: 'assets:subtypes.property.building',
    HYDRO_TEST: 'assets:subtypes.indust.hydrot',
    OVERHEAD_CRANE: 'assets:subtypes.indust.ovcrane',
    FIRST_AID: 'assets:subtypes.ee.firstAid',
    EYE_WASH_STATIONS: 'assets:subtypes.ee.eyewash',
    AEDS: 'assets:subtypes.ee.aed',
    SCBA: 'assets:subtypes.ee.scba',
    RESCUE_KIT: 'assets:subtypes.ee.rescue',
    FIRE_SAFETY_EQUIPMENT: 'assets:subtypes.ee.fireSafety',
    LIFE_PRESERVER: 'assets:subtypes.ee.lifePreserver',
    LIFE_RAFT: 'assets:subtypes.ee.lifeRaft',
    PERSONAL_LOCATOR_BEACON: 'assets:subtypes.ee.plb',
    RESCUE_EQUIPMENT: 'assets:subtypes.ee.rescueEquipment',
    EMPTY_STATE_FILTERS: 'assets:emptyState.noAssetsFoundFilters',
    EMPTY_STATE_WORKSPACE: 'assets:emptyState.noAssetsWorkspace',
    INSPECT: 'assets:inspect',
    INSPECTION_HISTORY: 'assets:inspectionHistory',
    DELETE_ASSET: 'assets:delete.asset',
    OUTCOME: 'assets:outcome',
    NO_INSPECTIONS_FOUND: 'assets:emptyState.noInspectionsFound',
    EDIT_INFORMATION: 'assets:editInformation',
    NO_FEATURES_FOUND: 'assets:emptyState.noFeaturesFound',
    ASSET_IMAGE: 'assets:fields.image',
    AIRCRAFT: 'assets:types.aircraft',
    HELICOPTER: 'assets:subtypes.aircraft.helicopter',
    PASSENGER: 'assets:subtypes.aircraft.passenger',
    CARGO: 'assets:subtypes.aircraft.cargo',
    SEARCH_AND_RESCUE: 'assets:subtypes.aircraft.sar',
    FIXED_WING: 'assets:subtypes.aircraft.fixedWing',
    BATTERY: 'assets:subtypes.tools.battery',
    UPDATES: 'assets:maintenance:updates',
    END_OF_LIFE_PLACEHOLDER: 'assets:maintenance:endOfLifePlaceholder',
    HOURS_PLACEHOLDER: 'assets:maintenance:hoursPlaceholder',
    MAINTENANCE_INFO: 'assets:maintenance:maintenanceInfo',
    MAINTENANCE_SUBHEADER: 'assets:maintenance:maintenanceSubheader',
    QUARANTINE: 'assets:status:quarantine',
    STOCK: 'assets:status:stock',
    DECOMMISSIONED: 'assets:status:decommissioned',
    ACTIVE: 'assets:status:active',
    IN_SERVICE: 'assets:status:inService',
    OUT_FOR_REPAIR: 'assets:status:outForRepair',
    OUT_FOR_SERVICE: 'assets:status:outForService',
    MAINTENANCE_SIDEBAR_SUBTEXT: 'assets:maintenance.sidebarSubtext',
  },
  roles: {
    EMPTY_STATE_ROLES_TITLE: 'roles:emptyState.title',
    EMPTY_STATE_ROLES_MESSAGE: 'roles:emptyState.message',
    CREATE_ROLE_TITLE: 'roles:newRoleState.createNewRole',
    NAME_ROLE_TITLE: 'roles:newRoleState.newRoleTitle',
    NAME_ROLE_DESCRIPTION: 'roles:newRoleState.newRoleDescription',
    ROLE_NAME_INPUT_PLACEHOLDER: 'roles:newRoleState.roleNamePlaceholder',
    ROLE_DESCRIPTION_INPUT_PLACEHOLDER:
      'roles:newRoleState.roleDescriptionPlaceholder',
    EDIT_ROLE: 'roles:editRole.editRoleTitle',
    EDIT_ROLE_INFO: 'roles:editRole.editRoleInfo',
    REQUIREMENTS_TITLE: 'roles:requirements.requirementTitle',
    REQUIREMENTS_DESCRIPTION: 'roles:requirements.requirementsDescription',
    EMPTY_STATE_REQUIREMENTS_TITLE:
      'roles:requirements.requirementsEmptyStateTitle',
    EMPTY_STATE_REQUIREMENTS_MESSAGE:
      'roles:requirements.requirementsEmptyStateMessage',
    ADD_REQUIREMENT: 'roles:requirements.addRequirement',
    CREATE_REQUIREMENT: 'roles:requirements.createRequirement',
    REQUIREMENT: 'roles:requirements.requirement',
  },
  assessments: {
    ASSESSMENT: 'assessments:assessment',
    EMPTY_STATE_TITLE: 'assessments:emptyState.title',
    EMPTY_STATE_FILTERS: 'assessments:emptyState.filters',
    EMPTY_STATE_MESSAGE: 'assessments:emptyState.message',
    APPROVE: 'assessments:action.approve',
    SUBMITTED_MESSAGE: 'assessments:messages.submit',
    AWAITING_APPROVAL: 'assessments:action.awaitingApproval',
    APPROVAL: 'assessments:action.approval',
    APPROVAL_CONFIRMATION: 'assessments:messages.verify',
    REQUEST_CHANGE: 'assessments:action.requestChange',
    REQUEST_ASSESSMENT_CHANGES: 'assessments:action.requestAssessmentChange',
    REQUEST_CHANGE_MESSAGE: 'assessments:messages.requestChange',
    REQUEST: 'assessments:action.request',
    CHANGE_REQUEST: 'assessments:action.changeRequest',
    CHANGE_REQUEST_FROM: 'assessments:action.changeRequestFrom',
    HAS_OBSERVATIONS: 'assessments:secondSight.hasObservations',
    HAS_OBSERVATIONS_DISCLAIMER:
      'assessments:secondSight.hasObservationsDisclaimer',
    NO_OBSERVATIONS: 'assessments:secondSight.noObservations',
    NO_OBSERVATIONS_DISCLAIMER:
      'assessments:secondSight.noObservationsDisclaimer',
    MODAL_LOADING_TITLE: 'assessments:secondSight.modalLoadingTitle',
    MODAL_LOADING_SUBTITLE: 'assessments:secondSight.modalLoadingSubtitle',
    MODAL_ANALYSIS_TITLE: 'assessments:secondSight.modalAnalysisTitle',
    MODAL_ANALYSIS_SUBTITLE: 'assessments:secondSight.modalAnalysisSubtitle',
    MODAL_ANALYSIS_FEEDBACK: 'assessments:secondSight.modalAnalysisFeedback',
    MODAL_ANALYSIS_FEEDBACK_PLACEHOLDER:
      'assessments:secondSight.modalAnalysisFeedbackPlaceholder',
    MODAL_NO_ANALYSIS_TITLE: 'assessments:secondSight.modalNoAnalysisTitle',
    MODAL_NO_ANALYSIS_SUBTITLE:
      'assessments:secondSight.modalNoAnalysisSubtitle',
    IGNORE: 'assessments:action.ignore',
    PARTICIPANT_MESSAGE: 'assessments:messages.participant',
    COMPLETE_ALL_QUESTIONS_MESSAGE: 'assessments:messages.allQuestions',
    NO_RESPONSE: 'assessments:messages.noResponse',
    VEHICLE_FAIL: 'assessments:failed.vehicleFail',
    REPORT_FAIL: 'assessments:failed.reportFail',
    REASON_FOR_FAILURE: 'assessments:failed.reasonForFailure',
    PASS: 'assessments:testTypes.pass',
    FAIL: 'assessments:testTypes.fail',
    OK: 'assessments:testTypes.ok',
    ISSUE: 'assessments:testTypes.issue',
    NA: 'assessments:testTypes.na',
    YES: 'assessments:testTypes.yes',
    NO: 'assessments:testTypes.no',
    WHAT_WENT_WRONG: 'assessments:failed.whatWentWrong',
    FAILURE_NOTES: 'assessments:failed.notes',
    NOTES_VALIDATION: 'assessments:failed.notesValidation',
    ASSIGN_FAILURE_ACTION_ITEM: 'assessments:failed.action',
    NEW_ROW: 'assessments:action.newRow',
    OFFLINE_APPROVAL_MESSAGE: 'assessments:messages.offline',
    START_ASSESSMENT: 'assessments:action.startAssessment',
    FAIL_IMAGE: 'assessments:failed.failImage',
    NO_ANSWER_PROVIDED: 'assessments:noAnswerProvided',
    RECORD_MEETING: 'assessments:meetingRecording.recordMeeting',
    SAFETY_TOPIC: 'assessments:meetingRecording.safetyTopic',
    MEETING_DESCRIPTION: 'assessments:meetingRecording.meetingDescription',
    LOCATION: 'assessments:meetingRecording.meetingLocation',
    EXTERNAL_PARTICIPANTS: 'assessments:meetingRecording.externalParticipanets',
    ATTACHMENT: 'assessments:meetingRecording.attachment',
    MESSAGE: 'assessments:meetingRecording.message',
    VOICE_RECORDER: 'assessments:meetingRecording.voiceRecorder',
    ENGLISH: 'assessments:meetingRecording.english',
    FRENCH: 'assessments:meetingRecording.french',
    SECOND_SIGHT_ANALYSIS: 'assessments:meetingRecording.secondSightAnalysis',
    CLOSE_CONFIRMATION_TITLE:
      'assessments:meetingRecording.closeConfirmationTitle',
    CLOSE_CONFIRMATION_MESSAGE:
      'assessments:meetingRecording.closeConfirmationMessage',
    SUBMIT_CONFIRMATION_MESSAGE:
      'assessments:meetingRecording.submitConfirmationMessage',
    SUBMIT_CONFIRMATION_TITLE:
      'assessments:meetingRecording.submitConfirmationTitle',
    SUBMIT_TITLE: 'assessments:meetingRecording.submitTitle',
    SUBMIT_MESSAGE: 'assessments:meetingRecording.submitMessage',
    MEETING_TRANSCRIPTION: 'assessments:meetingRecording.meetingTranscript',
    MEETING_RECORDING: 'assessments:meetingRecording.meetingRecording',
    RECORDED_MEETING: 'assessments:meetingRecording.recordedMeeting',
    MEETING_DETAILS: 'assessments:meetingRecording.meetingDetails',
    VIEW: 'assessments:meetingRecording.view',
    VOICE_DISCLAIMER: 'assessments:meetingRecording.voiceDisclaimer',
    VIEW_TRANSCRIPT: 'assessments:voiceQuestion.view',
    HIDE_TRANSCRIPT: 'assessments:voiceQuestion.hide',
    UNSUPPORTED_AUDIO: 'assessments:voiceQuestion.unsupportedAudio',
    STOP_RECORDING: 'assessments:voiceQuestion.stop',
    STOP_RECORDING_MESSAGE: 'assessments:voiceQuestion.stopMessage',
    MIC_ERROR: 'assessments:voiceQuestion.micError',
    RECORDING_ERROR: 'assessments:voiceQuestion.recordingError',
    VOICE_RECORDING: 'assessments:voiceQuestion.voiceRecording',
    CLEAR_RECORDING: 'assessments:voiceQuestion.clearRecording',
    CLEAR_RECORDING_WARNING: 'assessments:voiceQuestion.clearRecordingWarning',
    BROWSER_NOT_SUPPORTED: 'assessments:meetingRecording.browserNotSupported',
    RECOMMEND_CHROME: 'assessments:meetingRecording.recommendChrome',
    ENTER_MANUALLY: 'assessments:meetingRecording.enterManually',
    MEETING_ERROR: 'assessments:meetingRecording.error',
    REPEATING_BUNDLE: 'assessments:bundles.repeatingBundles',
    ADD_QUESTIONS: 'assessments:bundles.addQuestions',
    BUNDLE_INFO: 'assessments:bundles.info',
    BUNDLE: 'assessments:bundles.bundle',
    INVALID_QUESTION_TYPE: 'assessments:invalidQuestionType',
    VALUE_TOLERANCE: 'assessments:valueTolerance',
    MIN_MAX: 'assessments:minMax',
    FAIL_REPORT: 'assessments:failReport',
    DELETE_FAIL_REPORT: 'assessments:deleteFailReportMessage',
    DOCUMENT_NUMBER: 'assessments:documentNumber',
    DOCUMENT_NUMBER_DISCLAIMER: 'assessments:documentNumberDisclaimer',
    HISTORY: 'assessments:history',
    BACK_TO: 'assessments:backTo',
    FULL_NAME: 'assessments:fullName',
  },
  action_items: {
    PRIORITY: 'action_items:priority',
    LOW: 'action_items:low',
    MEDIUM: 'action_items:medium',
    HIGH: 'action_items:high',
    NOT_FOUND: 'action_items:emptyState.notFound',
    EMPTY_STATE_FILTERS: 'action_items:emptyState.filters',
    EMPTY_STATE_NEW: 'action_items:emptyState.new',
    OPEN: 'action_items:open',
    CLOSED: 'action_items:closed',
    CONVERSATION: 'action_items:modal.conversation',
    MESSAGE: 'action_items:modal.message',
    COMMENT: 'action_items:modal.comment',
    TITLE_PLACEHOLDER: 'action_items:modal.titlePlaceholder',
    DESCRIPTION_PLACEHOLDER: 'action_items:modal.descriptionPlaceholder',
    ACTIONS: 'action_items:actions',
    OOPS: 'action_items:error.oops',
    PROBLEM: 'action_items:error.problem',
    CLOSE: 'action_items:close',
    ACTIVITY: 'action_items:modal.activity',
    SEND: 'action_items:send',
    CLOSE_ACTION_ITEM: 'action_items:confirmation.close',
    CLOSE_ACTION_ITEM_MESSAGE: 'action_items:confirmation.closeMessage',
    ADD_ASSET: 'action_items:addAsset',
    DUE_DATE: 'action_items:dueDate',
    DUPLICATE: 'action_items:duplicate',
    PAGE_SUBTITLE: 'action_items:pageSubtitle',
    HIDE_COMPLETED: 'action_items:hideCompleted',
    ASSIGNED_TO_ME: 'action_items:assignedToMe',
    MAKE_PUBLIC_TITLE: 'action_items:makePublic.title',
    MAKE_PUBLIC_TEXT: 'action_items:makePublic.text',
    HIDDEN_ATTACHMENTS: 'action_items:hiddenAttachments',
    PRIVATE_TITLE: 'action_items:private.title',
    PRIVATE_TEXT: 'action_items:private.text',
    CREATION_ATTACHMENTS: 'action_items:creation.attachments',
    CREATION_PARTICIPANTS: 'action_items:creation.participants',
    PUBLIC: 'action_items:private.public',
    ACTION_ASSIGNED: 'action_items:actionAssigned',
  },
  dashboard: {
    DASHBOARD: 'dashboard:dashboard',
    STATS: 'dashboard:stats',
    QUICK_ACTIONS: 'dashboard:quickActions',
    RECENT_ASSESSMENTS: 'dashboard:recentAssessments',
    SAFETY_MOMENT: 'dashboard:toolbox.safetyMoment',
    INTRODUCTION: 'dashboard:toolbox.introduction',
    RISKS: 'dashboard:toolbox.risks',
    MITIGATION: 'dashboard:toolbox.mitigation',
    CONCLUSION: 'dashboard:toolbox.conclusion',
    MORE_INFORMATION: 'dashboard:toolbox.moreInfo',
    SAFETY_MESSAGE: 'dashboard:toolbox.safetyMessage',
    SAFETY_MOMENT_FOOTER: 'dashboard:toolbox.footer',
    ASSESSMENTS_EMPTY_STATE_TITLE: 'dashboard:emptyState.assessments.title',
    ASSESSMENTS_EMPTY_STATE_MESSAGE: 'dashboard:emptyState.assessments.message',
    ACTION_ITEMS_EMPTY_STATE_TITLE: 'dashboard:emptyState.actionItems.title',
    ACTION_ITEMS_EMPTY_STATE_MESSAGE:
      'dashboard:emptyState.actionItems.message',
    TOOLBOX_TALK: 'dashboard:toolbox.toolboxTalk',
    ASSESS: 'dashboard:assess',
    ACTION: 'dashboard:action',
    SEE_ALL: 'dashboard:seeAll',
    SAFETY_SHARE: 'dashboard:toolbox.safetyShare',
    MIN_READ: 'dashboard:toolbox.minRead',
    SHOW_MORE: 'dashboard:showMore',
    SHOW_LESS: 'dashboard:showLess',
    DAYS_SINCE_LAST_INCIDENT: 'dashboard:daysSinceLastIncident',
    OPEN_ACTION_ITEMS: 'dashboard:openActionItems',
    NEAR_MISSES: 'dashboard:nearMisses',
    ASSIGNED_ACTION_ITEMS: 'dashboard:assignedActionItems',
    MY_CONTRIBUTIONS: 'dashboard:myContributions',
    RECENT_ANNOUNCEMENTS: 'dashboard:recentAnnouncements',
    CLICK_TO_CHANGE_COLOR: 'dashboard:clickButtonToChangeColor',
    ICON: 'dashboard:icon',
    CUSTOM_QUICK_ACTION: 'dashboard:customQuickAction',
    UPDATED: 'dashboard:updated',
    INFO: 'dashboard:languageSelector.info',
    NEED_HELP: 'dashboard:languageSelector.needHelp',
    VISIT_HELP_CENTER: 'dashboard:languageSelector.visitHelpCenter',
    LANGUAGE: 'dashboard:languageSelector.language',
    CONTACT_US: 'dashboard:languageSelector.contactUs',
    ENGLISH: 'dashboard:languageSelector.english',
    FRENCH: 'dashboard:languageSelector.french',
    OFFLINE_30_DAYS: 'dashboard:offline30days',
  },
  workspace: {
    CREATE_NEW_WORKSPACE: 'workspace:createNew',
    WORKSPACE_NAME: 'workspace:workspaceName',
    LEAVE_WORKSPACE: 'workspace:confirmation.leaveWorkspace',
    LEAVE_WORKSPACE_DESCRIPTION:
      'workspace:confirmation.leaveWorkspaceDescription',
    WORKSPACE_SETTINGS: 'workspace:workspaceSettings',
    CREATE_NEW: 'workspace:createNew',
    WORKSPACE_DETAILS: 'workspace:workspaceDetails',
    WORKSPACE_USERS: 'workspace:workspaceUsers',
    WORKSPACE_ASSETS: 'workspace:workspaceAssets',
    WORKSPACE_NOTES: 'workspace:workspaceNotes',
    ACTIVE: 'workspace:fields.active',
    INACTIVE: 'workspace:fields.inactive',
    PROJECT_PHASE: 'workspace:fields.projectPhase',
    START_DATE: 'workspace:fields.startDate',
    END_DATE: 'workspace:fields.endDate',
    JOB_ID: 'workspace:fields.jobID',
    INTERNAL_JOB_ID: 'workspace:fields.internalJobID',
    JOB_CODE: 'workspace:fields.jobCode',
    PROJECT_VALUE: 'workspace:fields.projectValue',
    MILEAGE_RATE: 'workspace:fields.mileageRate',
    ACCOUNTING_EMAIL: 'workspace:fields.accountingEmail',
    ADMINISTRATORS: 'workspace:fields.administrators',
    EDITING_TITLE: 'workspace:confirmation.editingTitle',
    EDITING_MESSAGE: 'workspace:confirmation.editingMessage',
    NO_NOTES: 'workspace:confirmation.noNotes',
    DELETE_WORKSPACE: 'workspace:confirmation.delete',
    DELETE_MESSAGE: 'workspace:confirmation.deleteMessage',
    ADD_ASSET: 'workspace:confirmation.addAsset',
    NO_ASSETS: 'workspace:confirmation.noAssets',
    NO_ASSETS_TO_ADD: 'workspace:confirmation:noAssetsToAdd',
    LEAD: 'workspace:projectPhases.lead',
    BID: 'workspace:projectPhases.bid',
    SCHEDULED: 'workspace:projectPhases.scheduled',
    IN_PROGRESS: 'workspace:projectPhases.inProgress',
    COMPLETED: 'workspace:projectPhases.completed',
    CANCELED: 'workspace:projectPhases.canceled',
    INCIDENT_EMERGENCY_MESSAGE: 'workspace:incidentEmergencyMessage',
  },
  utilities: {
    LEFT_HEADER: 'utilities:signIn.leftHeader',
    LEFT_SUBTEXT: 'utilities:signIn.leftSubtext',
    WELCOME_BACK: 'utilities:signIn.welcomeBack',
    SIGN_IN: 'utilities:signIn.signIn',
    ERROR_PAGE_TITLE: 'utilities:errorPage.title',
    ERROR_PAGE_DESCRIPTION: 'utilities:errorPage.description',
    HELP: 'utilities:errorPage.help',
    LET_US_KNOW: 'utilities:errorPage.letUsKnow',
    REFRESH: 'utilities:errorPage.refresh',
    NO_ACCESS_TITLE: 'utilities:noAccess.title',
    NO_ACCESS_DESCRIPTION: 'utilities:noAccess.description',
    NO_ACCESS_REDIRECT: 'utilities:noAccess.redirect',
    NO_ACCESS_CONTACT: 'utilities:noAccess.contact',
    VIEW_WORKSPACES: 'utilities:noAccess.viewWorkspaces',
    PLEASE_CONTACT: 'utilities:errorPage.contact',
    NOT_FOUND_404_TITLE: 'utilities:notFound404.title',
    NOT_FOUND_404_DESCRIPTION: 'utilities:notFound404.description',
    NOT_FOUND_404_HOME: 'utilities:notFound404.homepage',
    SIGN_UP: 'utilities:signUp.signUp',
    PASSWORD: 'utilities:signUp.password',
    CONFIRM_PASSWORD: 'utilities:signUp.confirmPassword',
    AGREE_TO_TERMS: 'utilities:signUp.agreeToTerms',
    YOU_MUST_AGREE: 'utilities:signUp.youMustAgree',
    PASSWORDS_DONT_MATCH: 'utilities:signUp.passwordsDontMatch',
    PASSWORD_TOO_WEAK: 'utilities:signUp.passwordTooWeak',
    PLEASE_PROVIDE: 'utilities:signUp.pleaseProvide',
    REGISTER: 'utilities:signUp.register',
    ENTER_PASSWORD: 'utilities:signUp.enterPassword',
    ENTER_CONTACT_NUMBER: 'utilities:signUp.enterContactNumber',
    OR: 'utilities:errorPage:or',
    FILTERS: 'utilities:filtering.filters',
    DATERANGE: 'utilities:filtering.dateRange',
    TYPE: 'utilities:filtering.type',
    ASSET: 'utilities:filtering.asset',
    CREATOR: 'utilities:filtering.creator',
    STATUS: 'utilities:filtering.status',
    SUBTYPE: 'utilities:filtering.subtype',
    FILETYPE: 'utilities:filtering.fileType',
    ASSIGNEE: 'utilities:filtering.assignee',
    PRIORITY: 'utilities:filtering.priority',
    START: 'utilities:filtering.start',
    END: 'utilities:filtering.end',
    LOW: 'utilities:filtering.low',
    MEDIUM: 'utilities:filtering.medium',
    HIGH: 'utilities:filtering:high',
    IN_PROGRESS: 'utilities:filtering.inProgress',
    FAILED: 'utilities:filtering.failed',
    PASSED: 'utilities:filtering.passed',
    DRAFT: 'utilities:filtering.draft',
    PUBLISHED: 'utilities:filtering.published',
    SIGNOUT: 'utilities:signout',
    NOT_FOUND_403_TITLE: 'utilities:notFound403.title',
    NOT_FOUND_403_DESCRIPTION: 'utilities:notFound403.description',
    NOT_FOUND_403_HELP_ONE: 'utilities:notFound403.help.one',
    NOT_FOUND_403_HELP_LINK: 'utilities:notFound403.help.link',
    NOT_FOUND_403_HELP_TWO: 'utilities:notFound403.help.two',
    ID: 'utilities:ID',
    HIDDEN: 'utilities:filtering.hidden',
    PRIVATE: 'utilities:filtering:private',
    PRIVATE_DISCLAIMER: 'utilities:filtering.privateDisclaimer',
    ALL_WORKSPACE_DISCLAIMER: 'utilities:filtering.allWorkspaceDisclaimer',
    TO_DO: 'utilities:filtering.todo',
    BACKLOG: 'utilities:filtering.backlog',
    DUPLICATE: 'utilities:filtering.duplicate',
    COMPLETED: 'utilities:filtering.completed',
  },
  companySignUp: {
    MESSAGE_1: 'companySignUp:message.main',
    MESSAGE_2: 'companySignUp:message.sub',
    HEADER_P1: 'companySignUp:header.stage_one',
    HEADER_P2: 'companySignUp:header.stage_two',
    HEADER_P3: 'companySignUp:header.stage_three',
    HEADER_P4_COMPLETE: 'companySignUp:header.stage_four.complete',
    HEADER_P4_NOT_COMPLETE: 'companySignUp:header.stage_four.not_complete',
    INFO_NAME: 'companySignUp:info.name',
    INFO_EMAIL: 'companySignUp:info.email',
    INFO_PHONE: 'companySignUp:info.phone',
    DETAILS_DESCRIPTION: 'companySignUp:details.description.label',
    DETAILS_DESCRIPTION_PLACEHOLDER:
      'companySignUp:details.description.placeholder',
    DETAILS_QUANTITY: 'companySignUp:details.quantity',
    PAYMENT_FEATURES_AI: 'companySignUp:payment.features.ai',
    PAYMENT_FEATURES_INCIDENT: 'companySignUp:payment.features.incident',
    PAYMENT_FEATURES_TIME: 'companySignUp:payment.features.time',
    PAYMENT_FEATURES_ASSET: 'companySignUp:payment.features.asset',
    PAYMENT_FEATURES_CUSTOM: 'companySignUp:payment.features.custom',
    PAYMENT_STRINGS_TITLE: 'companySignUp:payment.strings.title',
    PAYMENT_STRINGS_PRICE: 'companySignUp:payment.strings.price',
    PAYMENT_STRINGS_DETAILS: 'companySignUp:payment.strings.details',
    PAYMENT_STRINGS_SUBTEXT: 'companySignUp:payment.strings.subtext',
    FINAL_NOT_COMPLETE: 'companySignUp:final.not_complete',
    FINAL_COMPLETE: 'companySignUp:final.complete',
    FINAL_TERMS_TEXT: 'companySignUp:final.terms.text',
    FINAL_TERMS_LINK: 'companySignUp:final.terms.link',
    FINAL_TERMS_WARNING: 'companySignUp:final.terms.warning',
    FINAL_ERROR: 'companySignUp:final.error',
    LEAVE_PAGE: 'companySignUp:final.leave',
  },
  companyRegistration: {
    WELCOME: 'companyRegistration:welcome',
    THRILLED: 'companyRegistration:thrilled',
    ACCOUNT_SETUP_PT1: 'companyRegistration:accountSetup.pt1',
    ACCOUNT_SETUP_PT2: 'companyRegistration:accountSetup.pt2',
    TOS: 'companyRegistration:tos',
    TRUSTED: 'companyRegistration:trusted',
    PRIVACY_POLICY: 'companyRegistration:privacyPolicy',
    COMPANY_REG_PT1: 'companyRegistration:companySetup.pt1',
    COMPANY_REG_PT2: 'companyRegistration:companySetup.pt2',
  },
  announcements: {
    SHOW_MORE: 'announcements:showMore',
    SNOOZE: 'announcements:snooze',
    ACCEPT: 'announcements:accept',
    SNOOZED_MESSAGE: 'announcements:snoozedMessage',
    ANNOUNCEMENT_SNOOZED: 'announcements:announcementSnoozed',
    ANNOUNCEMENT: 'announcements:announcement',
    ANNOUNCEMENTS: 'announcements:announcements',
    COMPLIANCE: 'announcements:compliance',
    NOTSEEN: 'announcements:notSeen',
    SNOOZED: 'announcements:snoozed',
    ACCEPTED: 'announcements:accepted',
    LAUNCHANNOUNCEMENT: 'announcements:launchAnnouncement',
    LAUNCH: 'announcements:launch',
    SCHEDULEYOURANNOUNCEMENT: 'announcements:scheduleYourAnnouncement',
    SCHEDULEMESSAGE: 'announcements:scheduleMessage',
    RELEASEDATE: 'announcements:releaseDate',
    RELEASEPLACEHOLDER: 'announcements:releasePlaceholder',
    NAMEYOURANNOUNCEMENT: 'announcements:nameYourAnnouncement',
    CUSTOMIZEYOURANNOUNCEMENT: 'announcements:customizeYourAnnouncement',
    ANNOUNCEMENTNAME: 'announcements:announcementName',
    EMPTYSTATETITLE: 'announcements:emptyStateTitle',
    EMPTYSTATEMESSAGE: 'announcements:emptyStateMessage',
    EMPTYSTATESUBMESSAGE: 'announcements:emptyStateSubmessage',
    ENTERANNOUNCEMENTNAME: 'announcements:enterAnnouncementName',
    ENTERSOMETEXT: 'announcements:enterSomeText',
  },
  observations: {
    OBSERVATION: 'observations:observation',
    OBSERVATIONS: 'observations:observations',
    MAKE_AN_OBSERVATION: 'observations:makeAnObservation',
    ANONYMOUS_SUBMISSION: 'observations:anonymousSubmission',
    ANONYMOUS: 'observations:anonymous',
    OBSERVATION_SUBMITTED: 'observations:observationSubmitted',
    OBSERVATION_SUBMITTED_MESSAGE: 'observations:observationSubmittedMessage',
    SUBMIT_ERROR: 'observations:submitError',
    SUBMIT_ERROR_MESSAGE: 'observations:submitErrorMessage',
    POSITIVE: 'observations:positive',
    ACTION_REQUIRED: 'observations:actionRequired',
    SELECT_DATE_AND_TIME: 'observations:selectDateAndTime',
    BRIEF_DESCRIPTION: 'observations:briefDescription',
    SELECT_TEAM: 'observations:selectTeam',
    DATE_AND_TIME: 'observations:dateAndTime',
    REVIEWER: 'observations:reviewer',
    THIS_WORKSPACE: 'observations:thisWorkspace',
    OBSERVATION_DETAILS: 'observations:observationDetails',
    TEAM_MEMBERS_PRESENT: 'observations:teamMembersPresent',
    ACTION: 'observations:action',
    ACKNOWLEDGE: 'observations:acknowledge',
    OBSERVER: 'observations:observer',
    REVIEWED_BY: 'observations:reviewedBy',
    DATE_REVIEWED: 'observations:dateReviewed',
    APPLY_TO_ALL_WORKSPACES: 'observations:applyToAllWorkspaces',
    OBSERVATION_TEMPLATE: 'observations:observationTemplate',
    APPLY_ALL_MESSAGE: 'observations:applyAllMessage',
    ALL_WORKSPACES_UPDATED: 'observations:allWorkspacesUpdated',
    ALL_WORKSPACES_UPDATED_MESSAGE: 'observations:allWorkspacesUpdatedMessage',
    QUESTIONS: 'observations:questions',
    START_ADDING_QUESTIONS: 'observations:startAddingQuestions',
    ADD_QUESTION_MESSAGE: 'observations:addQuestionMessage',
    CREATE_OBSERVATION_QUESTIONS: 'observations:createObservationQuestions',
    SELECT_TEMPLATE: 'observations:selectObservationTemplate',
    OBSERVATION_TEMPLATE_PREVIEW_TEXT:
      'observations:observationTemplatePreview',
    PREVIEW: 'observations:preview',
    HERE: 'observations:here',
    OBSERVATION_PREVIEW: 'observations:observationPreview',
    DEFAULT_TO_TEXT: 'observations:defaultedToText',
    RECORDABLE_INCIDENT: 'observations:recordableIncident',
    CLOSER: 'observations:closer',
    DATE_CLOSED: 'observations:dateClosed',
    ESCALATE: 'observations:escalate',
    PRIVATE_MESSAGE: 'observations:privateMessage',
    INVALID: 'observations:invalid.raw',
    INVALID_VARIABLE: 'observations:invalid.variable',
    INVALID_CONFIRMATION: 'observations:invalid.confirmation',
    INVALID_TOAST_TITLE: 'observations:invalidToast.title',
    INVALID_TOAST_MESSAGE: 'observations:invalidToast.message',
    ACKNOWLEDGED_BY: 'observations:acknowledgedBy',
    COMPLETE_MESSAGE: 'observations:completeMessage',
    COMPLETE_TITLE: 'observations:completeTitle',
  },
  tasks: {
    ONE_TIME: 'tasks:interval.oneTime',
    DAILY: 'tasks:interval.daily',
    WEEKLY: 'tasks:interval.weekly',
    MONTHLY: 'tasks:interval.monthly',
    INTERVAL: 'tasks:interval.header',
    NO_DATA_TEXT: 'tasks:noData.text',
    NO_DATA_TITLE: 'tasks:noData.title',
    CLEAR_SELECTION: 'tasks:clearSelection',
    DATA: 'tasks:data',
    TASK: 'tasks:task',
    CREATE_TASK_MAIN: 'tasks:create.main',
    CREATE_TASK_SECONDARY: 'tasks:create.secondary',
    ENTER_NAME: 'tasks:enterName',
    REASSIGN: 'tasks:reassign',
    MANAGE: 'tasks:tabs.manage',
    ANALYTICS: 'tasks:tabs.analytics',
    ERROR_EXISTS: 'tasks:errors.exists',
    ACCESS_DENIED: 'tasks:errors.accessDenied',
    MY_TASKS: 'tasks:myTask',
    NAME: 'tasks:name',
    TASKS: 'tasks:tasks',
    TYPE: 'tasks:type',
    TEMPLATE_TYPE: 'tasks:templateType',
  },
  toolbox: {
    TOOLBOX_TALK: 'toolbox:toolboxTalk',
    SAFETY_TOOLBOX: 'toolbox:safetyToolbox',
    LIBRARY: 'toolbox:library',
    SCHEDULE: 'toolbox:schedule',
    ASSIGN: 'toolbox:assign',
    ASSIGN_TOOLBOX_TALK: 'toolbox:assignToolboxTalk',
    ASSIGN_CONFIRMATION: 'toolbox:assignConfirmation',
    TBT_OPTION_MESSAGE_1: 'toolbox:tbtOptionMessage1',
    TBT_OPTION_MESSAGE_2: 'toolbox:tbtOptionMessage2',
    DELETE_DATA_MESSAGE: 'toolbox:deleteDataMessage',
    CLEAR_SELECTION: 'toolbox:clearSelection',
    SELECT_ALL: 'toolbox:selectAll',
    DELETE_SELECTED: 'toolbox:deleteSelected',
    RANDOM_TOPIC: 'toolbox:randomTopic',
    CLEAR_TOOLBOX_TALK: 'toolbox:clearToolboxTalk',
    CLEAR_TBT_MESSAGE: 'toolbox:clearTBTMessage',
    TBT_GENERATOR: 'toolbox:tbtGenerator',
    TBT_GENERATOR_MESSAGE: 'toolbox:tbtGeneratorMessage',
    TOPIC_10_CHAR: 'toolbox:topic10char',
    ERROR_GENERATING_CONTENT: 'toolbox:errorGeneratingContent',
    ERROR_GENERATING_CONTENT_MESSAGE: 'toolbox:errorGeneratingContentMessage',
    GENERATE_CONTENT: 'toolbox:generateContent',
    PUBLISH_TBT: 'toolbox:publishTBT',
    PUBLISH_TBT_MESSAGE: 'toolbox:publishTBTMessage',
    TBT_PUBLISHED: 'toolbox:tbtPublished',
    TBT_PUBLISHED_MESSAGE: 'toolbox:tbtPublishedMessage',
    THINKING: 'toolbox:thinking',
    THINKING_MESSAGE: 'toolbox:thinkingMessage',
    ENTER_TOPIC: 'toolbox:enterTopic',
    DEFAULT_DESCRIPTION: 'toolbox:defaultDescription',
    DELETE_DATA: 'toolbox:deleteData',
    TOOLBOX_TALK_SCHEDULE: 'toolbox:toolboxTalkSchedule',
    TOOLBOX: 'toolbox:toolbox',
    ASSIGN_TOAST_TITLE: 'toolbox:assignToastTitle',
    ASSIGN_TOAST_MESSAGE: 'toolbox:assignToastMessage',
  },
  expenses: {
    HEADERS_ID: 'expenses:headers.id',
    HEADERS_USER: 'expenses:headers.user',
    HEADERS_DATE: 'expenses:headers.date',
    HEADERS_DESCRIPTION: 'expenses:headers.description',
    HEADERS_STATUS: 'expenses:headers.status',
    HEADERS_TYPE: 'expenses:headers.type',
    HEADERS_TOTAL: 'expenses:headers.total',
    SEARCH_ID: 'expenses:searchId',
    EXPORT_MODAL_TITLE: 'expenses:exportModal',
    BATCH_UPDATE_SUCCESS_TITLE: 'expenses:batchUpdate.title',
    BATCH_UPDATE_SUCCESS_MESSAGE: 'expenses:batchUpdate.message',
    MY_EXPENSE: 'expenses:myExpense',
    TEAM_EXPENSE: 'expenses:teamExpense',
    TYPE_BILLED_TRAVEL: 'expenses:types.billedTravel',
    TYPE_FUEL: 'expenses:types.fuel',
    TYPE_MOTOR_VEHICLE: 'expenses:types.motorVehicle',
    TYPE_OFFICE: 'expenses:types.office',
    TYPE_PPE: 'expenses:types.ppe',
    TYPE_ENTERTAINMENT: 'expenses:types.entertainment',
    TYPE_MILEAGE: 'expenses:types.mileage',
    TYPE_ADVERTISING: 'expenses:types.advertising',
    TYPE_CLEANING: 'expenses:types.cleaning',
    TYPE_CONSULTING_ACCOUNTING: 'expenses:types.consulting',
    TYPE_PROFESSIONAL_FEES: 'expenses:types.professionalFee',
    TYPE_MEALS_ENTERTAINMENT: 'expenses:types.meals',
    TYPE_FREIGHT: 'expenses:types.freight',
    TYPE_GENERAL: 'expenses:types.general',
    TYPE_INSURANCE: 'expenses:types.insurance',
    TYPE_LEGAL: 'expenses:types.legal',
    TYPE_TRAINING: 'expenses:types.training',
    TYPE_PER_DIEM: 'expenses:types.perDiem',
    TYPE_PRINTING: 'expenses:types.printing',
    TYPE_RENT: 'expenses:types.rent',
    TYPE_REPAIR: 'expenses:types.repair',
    TYPE_BENEFITS: 'expenses:types.benefits',
    TYPE_SUBSCRIPTION: 'expenses:types.subscription',
    TYPE_INTERNET: 'expenses:types.internet',
    TYPE_TRAVEL_NATIONAL: 'expenses:types.travelNational',
    TYPE_TRAVEL_INTERNATIONAL: 'expenses:types.travelInternational',
    CURRENCY: 'expenses:currency',
    CURRENCY_CAD: 'expenses:currencies.cad',
    CURRENCY_USD: 'expenses:currencies.usd',
    CURRENCY_GBP: 'expenses:currencies.gbp',
    CURRENCY_EUR: 'expenses:currencies.eur',
    SUBTOTAL: 'expenses:bottomInputs.subtotal',
    TOTAL: 'expenses:bottomInputs.total',
    TIP: 'expenses:bottomInputs.tip',
    OTHER_FEE: 'expenses:bottomInputs.otherFee',
    TAX: 'expenses:bottomInputs.tax',
    DRAFT: 'expenses:status.draft',
    PENDING: 'expenses:status.pending',
    APPROVED: 'expenses:status.approved',
    APPROVE_DESCRIPTION: 'expenses:approve.message',
    COMPLETE: 'expenses:status.complete',
    DENIED: 'expenses:status.denied',
    REJECT_BUTTON: 'expenses:reject.button',
    REJECT_TITLE: 'expenses:reject.title',
    REJECT_DESCRIPTION: 'expenses:reject.message',
    REJECT_INPUT: 'expenses:reject.input',
    COMPLETE_TITLE: 'expenses:complete.title',
    COMPLETE_MESSAGE: 'expenses:complete.message',
    DISTANCE: 'expenses:distance',
    DISTANCE_NO_VARIABLE: 'expenses:distanceNoVariable',
    MILEAGE_RATE: 'expenses:mileageRate',
    ATTACHEMENTS: 'expenses:pdf.attachments',
    NOT_SUPPORTED: 'expenses:pdf.notSupported',
    PDF_EXPENSE_ID: 'expenses:pdf.expenseId',
    PDF_PAYROLL_ID: 'expenses:pdf.payrollId',
    PDF_RESOURCE_ID: 'expenses:pdf.resourceId',
    PDF_ACTIVITY: 'expenses:pdf.activity',
    REPORT_FOR: 'expenses:pdf.reportFor',
    EXPORT_BY: 'expenses:pdf.exportBy',
    DETAILS: 'expenses:pdf.details',
  },
  timecards: {
    BATCH_UPDATE_SUCCESS_TITLE: 'timecards:batchUpdate.title',
    BATCH_UPDATE_SUCCESS_MESSAGE: 'timecards:batchUpdate.message',
    ACTIVITY_ERROR_TITLE: 'timecards:activity.batchUpdateTitle',
    ACTIVITY_ERROR_MESSAGE: 'timecards:activity.batchUpdateMessage',
    EXPORT_MODAL_TITLE: 'timecards:exportModalTitle',
    DRAFT: 'timecards:status.draft',
    SUBMITTED: 'timecards:status.submitted',
    APPROVED: 'timecards:status.approved',
    COMPLETE: 'timecards:status.complete',
    DENIED: 'timecards:status.denied',
    START_TIME: 'timecards:fields.startTime',
    END_TIME: 'timecards:fields.endTime',
    HOURS: 'timecards:fields.hours',
    PO_NUMBER: 'timecards:fields.poNumber',
    ACTIVITY: 'timecards:fields.activity',
    DESCRIPTION: 'timecards:fields.description',
    NON_WORKING_HOURS: 'timecards:fields.nonBillableHours',
    TOTAL_WORKING_HOURS: 'timecards:fields.billableHours',
    ASSET: 'timecards:fields.asset',
    APPROVE_MESSAGE: 'timecards:approve.message',
    DENY_MESSAGE: 'timecards:deny.message',
    DENY_INPUT: 'timecards:deny.input',
    SUBMIT_TITLE: 'timecards:submit.title',
    SUBMIT_MESSAGE: 'timecards:submit.message',
    COMPLETE_TITLE: 'timecards:complete.title',
    COMPLETE_MESSAGE: 'timecards:complete.message',
    MY_TIMECARD: 'timecards:myTimecard',
    TEAM_TIMECARD: 'timecards:teamTimecard',
    SUB_HEADER: 'timecards:headers.subHeader',
    REVERT_STATUS: 'timecards:revert.status',
    REVERT_MESSAGE: 'timecards:revert.message',
    REVERT: 'timecards:revert.button',
    NOTES: 'timecards:notes',
    NEW_HEADER_1: 'timecards:headers.one',
    NEW_HEADER_2: 'timecards:headers:two',
    NEW_HEADER_3: 'timecards:headers:three',
    NEW_DESCRIPTION: 'timecards:newDescription',
    DAILY_REPORT_TITLE: 'timecards:dailyReport:title',
    DAILY_REPORT_MESSAGE: 'timecards:dailyReport:message',
    NO_REPORT: 'timecards:dailyReport.none',
    START_REPORT: 'timecards:dailyReport.start',
  },
  boards: {
    NEW_BOARD_TITLE: 'boards:new.title',
    NEW_BOARD_MESSAGE_ONE: 'boards:new.message.one',
    NEW_BOARD_MESSAGE_TWO: 'boards:new.message.two',
    BOARD_NAME: 'boards:name.name',
    ENTER_BOARD_NAME: 'boards:name.placeholder',
    BOARD_DESCRIPTION: 'boards:description.description',
    ENTER_BOARD_DESCRIPTION: 'boards:description.placeholder',
    DELETE_BOARD_CONFIRM: 'boards:deleteBoard.title',
    DELETE_BOARD_MESSAGE: 'boards:deleteBoard.message',
    EMPTY_STATE_BOARDS_HEADER: 'boards:emptyState.boards.title',
    EMPTY_STATE_BOARDS_MESSAGE: 'boards:emptyState.boards.message',
    GRAPH: 'boards:graphs.single',
    GRAPHS: 'boards:graphs.plural',
    RANGE: 'boards:sidebar.range',
    FILTER: 'boards:sidebar.filter',
    ACTION: 'boards:sidebar.action',
    GROUP: 'boards:sidebar.group',
    GRAPH_GET_READY: 'boards:getReady',
    NO_EXPORT_TITLE: 'boards:noExport.title',
    NO_EXPORT_MESSAGE: 'boards:noExport.message',
    TOTAL_RANGE: 'boards:totalRange',
    EMPTY_STATE_GRAPH_HEADER: 'boards:emptyState.graphs.title',
    EMPTY_STATE_GRAPH_MESSAGE: 'boards:emptyState.graphs.message',
    ACTION_ITEM_CREATED: 'boards:events.actionItemCreated',
    ASSESSMENT_COMPLETED: 'boards:events.assessmentCompleted',
    INCIDENT_REPORTED: 'boards:events.incidentReported',
    QUESTION_ANSWERED: 'boards:events.questionAnswered',
    OBSERVATION_REPORTED: 'boards:events.observationReported',
    ASSESSMENT_PARTICIPATION: 'boards:events.assessmentParticipation',
    LINE_GRAPH: 'boards:graphs.line',
    PIE_GRAPH: 'boards:graphs.pie',
    CALENDAR_GRAPH: 'boards:graphs.calendar',
    BAR_GRAPH: 'boards:graphs.bar',
    PRIMARY_GROUP_NONE: 'boards:groups.primary.none',
    PRIMARY_GROUP_USER: 'boards:groups.primary.user',
    PRIMARY_GROUP_WORKSPACE: 'boards:groups.primary.workspace',
    SECONDARY_GROUP_ASSET: 'boards:groups.secondary.asset',
    SECONDARY_GROUP_STATUS: 'boards:groups.secondary.status',
    SECONDARY_GROUP_INCIDENT: 'boards:groups.secondary.incident',
    SECONDARY_GROUP_PRIORITY: 'boards:groups.secondary.priority',
    SECONDARY_GROUP_INSPECTION: 'boards:groups.secondary.inspection',
    SECONDARY_GROUP_TYPE: 'boards:groups.secondary.type',
    SECONDARY_GROUP_OUTCOME: 'boards:groups.secondary.outcome',
    SECONDARY_GROUP_TEMPLATE: 'boards:groups.secondary.template',
    SECONDARY_GROUP_ANSWER: 'boards:groups.secondary.answer',
    SECONDARY_GROUP_QUESTION: 'boards:groups.secondary.question',
    SECONDARY_GROUP_ASSESSMENT: 'boards:groups.secondary.assessment',
    SECONDARY_GROUP_QUESTION_TYPE: 'boards:groups.secondary.questionType',
    SECONDARY_GROUP_ASSESSMENT_TYPE: 'boards:groups.secondary.assessmentType',
    SECONDARY_GROUP_SUBTYPE: 'boards:groups.secondary.subtype',
    SECONDARY_GROUP_IMAGES: 'boards:groups.secondary.images',
    SECONDARY_GROUP_LOCATION: 'boards:groups.secondary.location',
    COUNT: 'boards:count',
  },
  notifications: {
    NOTIFICATIONS: 'notifications:notifications',
    EXPAND_NOTIFICATIONS: 'notifications:expand',
    DUE: 'notifications:due.due',
    DUE_TODAY: 'notifications:due.timePeriod.today',
    DUE_TOMORROW: 'notifications:due.timePeriod.tomorrow',
    PAST_DUE: 'notifications:due.timePeriod.past',
    EXPIRED: 'notifications:expires.timePeriod.past',
    EXPIRES_TODAY: 'notifications:expires.timePeriod.today',
    EXPIRES_TOMORROW: 'notifications:expires.timePeriod.tomorrow',
    EXPIRES_MONTH: 'notifications:expires.timePeriod.month',
    OBSERVATION_SUBMITTED_TITLE: 'notifications:observations.submitted.title',
    OBSERVATION_SUBMITTED_DESCRIPTION:
      'notifications:observations.submitted.description',
    OBSERVATION_REVIEWED_TITLE: 'notifications:observations.reviewed.title',
    OBSERVATION_REVIEWED_DESCRIPTION:
      'notifications:observations.reviewed.description',
    ACTION_ITEM_COMPLETE_TITLE: 'notifications:actionItems.complete.title',
    ACTION_ITEM_COMPLETE_DESCRIPTION:
      'notifications:actionItems.complete.description',
    ACTION_ITEM_DUE_DATE_TITLE: 'notifications:actionItems.dueDate.title',
    ACTION_ITEM_DUE_DATE_DESCRIPTION:
      'notifications:actionItems.dueDate.description',
    ACTION_ITEM_REMINDER_TITLE: 'notifications:actionItems.reminder.title',
    ACTION_ITEM_REMINDER_DESCRIPTION:
      'notifications:actionItems.reminder.description',
    ACTION_ITEM_COMMENT_TITLE: 'notifications:actionItems.comment.title',
    ACTION_ITEM_ASSIGNED_TITLE: 'notifications:actionItems.assigned.title',
    ACTION_ITEM_ASSIGNED_DESCRIPTION:
      'notifications:actionItems.assigned.description',
    ACTION_ITEM_PARTICIPANT_TITLE:
      'notifications:actionItems.participant.title',
    ACTION_ITEM_PARTICIPANT_DESCRIPTION:
      'notifications:actionItems.participant.description',
    ASSESSMENT_DESCRIPTION: 'notifications:assessments.complete.description',
    ASSESSMENT_PARTICIPANTS: 'notifications:assessments.complete.title',
    INCIDENT_REPORTED_TITLE: 'notifications:incidents.reported.title',
    INCIDENT_REPORTED_DESCRIPTION:
      'notifications:incidents.reported.description',
    INCIDENT_DEESCALATED_TITLE: 'notifications:incidents.deescalated.title',
    INCIDENT_DEESCALATED_DESCRIPTION:
      'notifications:incidents.deescalated.description',
    INCIDENT_DISMISSED_TITLE: 'notifications:incidents.dismissed.title',
    INCIDENT_DISMISSED_DESCRIPTION:
      'notifications:incidents.dismissed.description',
    FILE_TITLE: 'notifications:files.expiry.title',
    FILE_DESCRIPTION: 'notifications:files.expiry.description',
    INCIDENT_APPROVED_TITLE: 'notifications:incidents.approved.title',
    INCIDENT_APPROVED_DESCRIPTION:
      'notifications:incidents.approved.description',
    INCIDENT_CLOSED_TITLE: 'notifications:incidents.closed.title',
    INCIDENT_CLOSED_DESCRIPTION: 'notifications:incidents.closed.description',
    EXPENSE_DENIED_TITLE: 'notifications:expenses.denied.title',
    EXPENSE_DENIED_DESCRIPTION: 'notifications:expenses.denied.description',
    EXPENSE_SUBMITTED_TITLE: 'notifications:expenses.submitted.title',
    EXPENSE_SUBMITTED_DESCRIPTION:
      'notifications:expenses.submitted.description',
    TIMECARD_DENIED_TITLE: 'notifications:timecards.denied.title',
    TIMECARD_DENIED_DESCRIPTION: 'notifications:timecards.denied.description',
    ASSESSMENT_REGRET_TITLE: 'notifications:assessments.regret.title',
    ASSESSMENT_REGRET_DESCRIPTION:
      'notifications:assessments.regret.description',
    ASSET_ASSESSED_TITLE: 'notifications:assets.assessed.title',
    ASSET_ASSESSED_DESCRIPTION: 'notifications:assets.assessed.description',
    ASSET_UPDATED_TITLE: 'notifications:assets.updated.title',
    ASSET_UPDATED_DESCRIPTION: 'notifications:assets.updated.description',
    TEMPLATE_REVISED_TITLE: 'notifications:templates.revised.title',
    TEMPLATE_REVISED_DESCRIPTION: 'notifications:templates.revised.description',
    TEMPLATE_USED_TITLE: 'notifications:templates.used.title',
    TEMPLATE_USED_DESCRIPTION: 'notifications:templates.used.description',
    TEMPLATE_SUBMITTED_TITLE: 'notifications:templates.submitted.title',
    TEMPLATE_SUBMITTED_DESCRIPTION:
      'notifications:templates.submitted.description',
    FILE_DELETED_TITLE: 'notifications:files.deleted.title',
    FILE_DELETED_DESCRIPTION: 'notifications:files.deleted.description',
    FILE_REVISION_TITLE: 'notifications:files.revision.title',
    FILE_REVISION_DESCRIPTION: 'notifications:files.revision.description',
    SELECT_ALL: 'notifications:selectAll',
    MARK_AS_READ: 'notifications:markAsRead',
    DELETED_TITLE: 'notifications:deleted.title',
    DELETED_DESCRIPTION: 'notifications:deleted.description',
    ASSESSMENT_CHANGE_REQUEST_TITLE:
      'notifications:assessments.changeRequest.title',
    ASSESSMENT_CHANGE_REQUEST_DESCRIPTION:
      'notifications:assessments.changeRequest.description',
    ASSESSMENT_REVIEW_TITLE: 'notifications:assessments.readyForReview.title',
    ASSESSMENT_REVIEW_DESCRIPTION:
      'notifications:assessments.readyForReview.description',
  },
};

export const namespaces = Object.keys(keys);
export function getTranslationKey(str, namespace) {
  if (!namespace) {
    return null;
  }

  const key = str
    ?.toUpperCase()
    .replace(/[,\s/]+/g, '_')
    .replace(/[_()-]+/g, '_')
    .replace(/_+/g, '_');

  return keys[namespace][key] ?? null;
}
export function getEnglishKey(translatedOption, map) {
  for (const [key, value] of Object.entries(map)) {
    if (value === translatedOption) {
      return key;
    }
  }
  return translatedOption;
}
